import "../styles/main.scss";
import "../styles/quill.scss";
import PageTitle from "../compo/atoms/PageTitle";
import Input from "../compo/atoms/Input";
import ImageUpload from "../compo/items/ImageUpload";
import Select from "../compo/atoms/Select";
import { useEffect, useState } from "react";
import PostForm from "../compo/templates/PostForm";
import Separator from "../compo/atoms/Separator";
import countries from "../data/countires.json";
import { CreateCompany, CreateCompanyPost } from "../services/Company";
import { getToken } from "../services/User";
import { CreatePost } from "../services/Post";
import { signupValidation } from "../validation";
import { useDispatch, useSelector } from "react-redux";
import prefectures from "../data/prefectures.json";
import { changeLanguage } from "../redux/actions";
import TopPageIntro2 from "../compo/items/TopPageIntro2";
import pirsch from "../Analytics";

function EntryForm({ showPopup, showInfoPopup }) {
  // React state and redux --------------------------------------------------------------------------------
  const dispatch = useDispatch();
  const lang = useSelector((state) => state.lang);
  const [data, setData] = useState({});
  const [desc, setDesc] = useState();
  const [errors, setErrors] = useState({});
  const [image, setImage] = useState();
  const [imagePreview, setImagePreview] = useState();
  const IsTermsAccepted = localStorage.getItem("IsTermsAccepted");

  // Changing the input fields using stae ----------------------------------------------------------

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };

  // validation checkers -----------------------------------------------------------------

  const isUsernameValid = (username) => {
    return /^[a-z0-9_]+$/.test(username);
  };

  // submitting the form ---------------------------------------------------------------------------------------------

  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    setErrors(signupValidation(data, desc, image, lang));
    let x = signupValidation(data, desc, image, lang);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      showInfoPopup("success", "Connecting!...", 4000, "right_arrow_ani");
      CreateCompanyPost(data, image, desc, true, lang)
        .then((res) => {
          setData({});
          setDesc({});
          showInfoPopup(false);
          setImage();
          setImagePreview();
          showPopup(
            lang === "en" ? "DONE" : "ありがとうございます。",
            lang === "en"
              ? "The post has been successfully created and published!"
              : "ご協力をありがとうございます。投稿が正常に完了しました。"
          );
        })
        .catch((error) => {
          if (error?.response?.data.error) {
            showInfoPopup("error", error?.response?.data.error);
            let errorFields = error?.response?.data?.fields;
            if (errorFields) {
              errorFields.forEach((element) => {
                setErrors({
                  ...errors,
                  [`${element}`]: error?.response?.data.error,
                });
              });
            }
          } else {
          }
        });
    } else {
      if (Object.keys(x).length === 1) {
        showInfoPopup("error", x[Object.keys(x)[0]]);
      } else if (Object.keys(x).length >= 1) {
        showInfoPopup(
          "error",
          lang === "en"
            ? "Please fill required fields with valid data"
            : "必須項目を適切に入力してください。 "
        );
      }
    }
  };
  // useEffect ------------------------------------------------
  useEffect(() => {
    if (!localStorage.getItem("IsTermsAccepted")) {
      window.location = "entry-page";
    }
    dispatch(changeLanguage("jp"));
  }, []);

  useEffect(() => {
    if (image) {
      setErrors((prev) => {
        delete prev.image;
        return { ...prev };
      });
    }
  }, [image]);

  return (
    <>
      {IsTermsAccepted &&
        (lang === "en" ? (
          <div className="page_section_box">
            <PageTitle title="Sign Up & Post a JOB" />
            <Separator />
            <PageTitle
              sectionTitle="ACCOUNT INFO (FIRST USE)"
              text="Automatically create the company profile and all job information"
            />
            <div className="username_input_box">
              <span>Pick up your username</span>
              <Input
                focusedIconName="add"
                defaultIconName="cal"
                placeholder="username"
                className="input_def username"
                onChange={(e) => {
                  if (isUsernameValid(e) || e.length === 0) {
                    handleChange("username", e);
                  }
                }}
                value={data?.username}
                error={errors?.username}
                customIcon={
                  <p className="username-counter">
                    {16 - data?.username?.length}
                  </p>
                }
                maxLength={16}
                pretext={<>pinjob.me/</>}
              />
            </div>
            <div className="company_info_input_box">
              <div className="company_image_box">
                <ImageUpload
                  setImage={(res) => setImage(res)}
                  error={errors?.image}
                  imagePreview={imagePreview}
                  setImagePreview={(res) => setImagePreview(res)}
                  edit={true}
                />
              </div>
              <div className="company_info_inputs_box">
                <Input
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Name"
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                />
                <Input
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Email"
                  onChange={(e) => handleChange("email", e)}
                  value={data?.email}
                  error={errors?.email}
                />
                <Input
                  placeholder=""
                  className="input_def signup_username_input"
                  label="Company Name"
                  onChange={(e) => handleChange("company", e)}
                  value={data?.company}
                  error={errors?.company}
                />
                <Input
                  placeholder=""
                  className="input_def signup_username_input"
                  label="URL"
                  onChange={(e) => handleChange("url", e)}
                  value={data?.url}
                  error={errors?.url}
                />
                <Select
                  placeholder=""
                  defaultIconName="drop_down"
                  focusedIconName="drop_up"
                  className=" signup_username_input"
                  label="Country"
                  options={countries}
                  flag={true}
                  selectedOption={data?.country_user}
                  error={errors?.country_user}
                  setSelectedOption={(e) => handleChange("country_user", e)}
                  search={true}
                  clearIcon={true}
                />
                <Input
                  placeholder=""
                  className="input_def signup_username_input"
                  label="City"
                  onChange={(e) => handleChange("city_user", e)}
                  value={data?.city_user}
                  error={errors?.city_user}
                  maxLength={16}
                />
              </div>
            </div>
            <Separator />
            <PostForm
              countries={countries}
              data={data}
              errors={errors}
              handleChange={(res1, res2) => {
                handleChange(res1, res2);
              }}
              handleSubmit={() => handleSubmit()}
              setErrors={(res) => {
                setErrors(res);
              }}
              setData={(res) => {
                setData(res);
              }}
              desc={desc}
              setDesc={(res) => {
                setDesc(res);
                errors.job_description = false;
              }}
            />
          </div>
        ) : (
          // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPANESE
          <>
            <TopPageIntro2 iconName="illust_11" />
            <div className="page_section_box">
              <PageTitle title="会員登録" />
              <Separator />
              <PageTitle
                sectionTitle="会社情報"
                text="会員情報を入力してください。"
              />

              <div className="company_info_input_box">
                <div className="username_input_box">
                  <span>ユーザーネーム:</span>
                  <Input
                    focusedIconName="add"
                    defaultIconName="cal"
                    placeholder="username"
                    className="input_def username"
                    onChange={(e) => {
                      if (isUsernameValid(e) || e.length === 0) {
                        handleChange("username", e);
                      }
                    }}
                    value={data?.username}
                    error={errors?.username}
                    customIcon={
                      <p className="username-counter">
                        {" "}
                        {data?.username?.length
                          ? 16 - data?.username?.length
                          : 16}
                      </p>
                    }
                    maxLength={16}
                    pretext={<>pinjob.me/</>}
                  />
                </div>
                <div className="company_image_box">
                  <ImageUpload
                    setImage={(res) => setImage(res)}
                    error={errors?.image}
                    imagePreview={imagePreview}
                    setImagePreview={(res) => setImagePreview(res)}
                    edit={true}
                  />
                </div>
                <div className="company_info_inputs_box">
                  <Input
                    placeholder=""
                    className="input_def signup_username_input"
                    label="お名前"
                    onChange={(e) => handleChange("name", e)}
                    value={data?.name}
                    error={errors?.name}
                  />
                  <Input
                    placeholder=""
                    className="input_def signup_username_input"
                    label="メールアドレス"
                    onChange={(e) => handleChange("email", e)}
                    value={data?.email}
                    error={errors?.email}
                  />
                  <Input
                    placeholder=""
                    className="input_def signup_username_input"
                    label="社名"
                    onChange={(e) => handleChange("company", e)}
                    value={data?.company}
                    error={errors?.company}
                  />
                  <Input
                    placeholder=""
                    className="input_def signup_username_input"
                    label="会社のURL"
                    onChange={(e) => handleChange("url", e)}
                    value={data?.url}
                    error={errors?.url}
                  />

                  <Select
                    placeholder=""
                    defaultIconName="drop_down"
                    focusedIconName="drop_up"
                    className=" "
                    label="都道府県"
                    options={prefectures}
                    flag={true}
                    selectedOption={data?.prefecture_user}
                    error={errors?.prefecture_user}
                    setSelectedOption={(e) =>
                      handleChange("prefecture_user", e)
                    }
                    search={true}
                    clearIcon={true}
                    allOption={{ label: "都道府県を選ぶ", iconName: "map" }}
                    searchList={prefectures}
                  />
                  <Input
                    className="input_def signup_username_input"
                    label="市区町村 or 近い駅"
                    placeholder="市区町村/近い駅を入力"
                    onChange={(e) => handleChange("city_user", e)}
                    value={data?.city_user}
                    error={errors?.city_user}
                    maxLength={16}
                  />
                </div>
              </div>
              <Separator />
              <PostForm
                countries={countries}
                data={data}
                errors={errors}
                handleChange={(res1, res2) => {
                  handleChange(res1, res2);
                }}
                handleSubmit={() => handleSubmit()}
                setErrors={(res) => {
                  setErrors(res);
                }}
                setData={(res) => {
                  setData(res);
                }}
                desc={desc}
                setDesc={(res) => {
                  setDesc(res);
                  errors.job_description = false;
                }}
              />
            </div>
          </>
        ))}
    </>
  );
}

export default EntryForm;
