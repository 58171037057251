// svgImports.js

import svg1 from "../../assets/Prefs/1.svg";
import svg2 from "../../assets/Prefs/2.svg";
import svg3 from "../../assets/Prefs/3.svg";
import svg4 from "../../assets/Prefs/4.svg";
import svg5 from "../../assets/Prefs/5.svg";
import svg6 from "../../assets/Prefs/6.svg";
import svg7 from "../../assets/Prefs/7.svg";
import svg8 from "../../assets/Prefs/8.svg";
import svg9 from "../../assets/Prefs/9.svg";
import svg10 from "../../assets/Prefs/10.svg";
import svg11 from "../../assets/Prefs/11.svg";
import svg12 from "../../assets/Prefs/12.svg";
import svg13 from "../../assets/Prefs/13.svg";
import svg14 from "../../assets/Prefs/14.svg";
import svg15 from "../../assets/Prefs/15.svg";
import svg16 from "../../assets/Prefs/16.svg";
import svg17 from "../../assets/Prefs/17.svg";
import svg18 from "../../assets/Prefs/18.svg";
import svg19 from "../../assets/Prefs/19.svg";
import svg20 from "../../assets/Prefs/20.svg";
import svg21 from "../../assets/Prefs/21.svg";
import svg22 from "../../assets/Prefs/22.svg";
import svg23 from "../../assets/Prefs/23.svg";
import svg24 from "../../assets/Prefs/24.svg";
import svg25 from "../../assets/Prefs/25.svg";
import svg26 from "../../assets/Prefs/26.svg";
import svg27 from "../../assets/Prefs/27.svg";
import svg28 from "../../assets/Prefs/28.svg";
import svg29 from "../../assets/Prefs/29.svg";
import svg30 from "../../assets/Prefs/30.svg";
import svg31 from "../../assets/Prefs/31.svg";
import svg32 from "../../assets/Prefs/32.svg";
import svg33 from "../../assets/Prefs/33.svg";
import svg34 from "../../assets/Prefs/34.svg";
import svg35 from "../../assets/Prefs/35.svg";
import svg36 from "../../assets/Prefs/36.svg";
import svg37 from "../../assets/Prefs/37.svg";
import svg38 from "../../assets/Prefs/38.svg";
import svg39 from "../../assets/Prefs/39.svg";
import svg40 from "../../assets/Prefs/40.svg";
import svg41 from "../../assets/Prefs/41.svg";
import svg42 from "../../assets/Prefs/42.svg";
import svg43 from "../../assets/Prefs/43.svg";
import svg44 from "../../assets/Prefs/44.svg";
import svg45 from "../../assets/Prefs/45.svg";
import svg46 from "../../assets/Prefs/46.svg";
import svg47 from "../../assets/Prefs/47.svg";

// Export SVG files as variables
export default {
  svg1,
  svg2,
  svg3,
  svg4,
  svg5,
  svg6,
  svg7,
  svg8,
  svg9,
  svg10,
  svg11,
  svg12,
  svg13,
  svg14,
  svg15,
  svg16,
  svg17,
  svg18,
  svg19,
  svg20,
  svg21,
  svg22,
  svg23,
  svg24,
  svg25,
  svg26,
  svg27,
  svg28,
  svg29,
  svg30,
  svg31,
  svg32,
  svg33,
  svg34,
  svg35,
  svg36,
  svg37,
  svg38,
  svg39,
  svg40,
  svg41,
  svg42,
  svg43,
  svg44,
  svg45,
  svg46,
  svg47,
};
