import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import CompanyPic from "../../compo/atoms/CompanyPic";
import CompanyCard from "../../compo/items/CompanyCard";
import { BACKEND_URL } from "../../config";
import { useEffect, useState } from "react";
import Option from "../atoms/Option";
import { changeLanguage } from "../../redux/actions";
import { useDispatch, useSelector } from "react-redux";

export default function HeaderLogged({ text, className, companyData, imgSrc }) {
  const lang = useSelector((state) => state.lang);
  const [showPicPopup, setShowPicPopup] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1) {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "88px";
          el.style.borderBottom = "2px solid #00000010";
        });

        // document.querySelector(".header-mockup").style.display = "block";
      } else {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "120px";
          el.style.borderBottom = "1px solid transparent";
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (

       

    <>
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL-  */}
          {lang == "jp" ? (

      <div className="header_box">
        <div className="logo_box">
          <CustomIcon iconName="logo_mark" className="logo_mark" />
          <CustomIcon iconName="logo_type" />
        </div>
        <div className="nav_link_box to_left">
          <Nav name="JOBS" link="/home" icon="jobs" hoverIcon="jobs_ani" />
          <Nav
            name="COMPANIES"
            link="/companies"
            icon="company"
            hoverIcon="company_ani"
          />
        </div>

        <div className="buttons_box, buttons_box_br1020">
          <Button
            text="A JOB"
            text2="POST"
            icon1="pinjob"
            icon2="pinjob_ani"
            className={"hide_post_btn"}
            onClick={() => {
              window.location = "/post/create";
            }}
          />
          <div className="nav_link_box  hide_box_on_mobile">
            <Nav
              name="MY JOBS"
              icon="jobs"
              link="/myjobs"
              hoverIcon="pinjob"
              number={companyData?.posts_number}
            />
          </div>

          <div className="company_header_pic_cont">
            <CompanyPic
              className="company_header_pic"
              imgSrc={`http://178.16.142.189:8002${imgSrc}`}
              onClick={() => {
                setShowPicPopup(!showPicPopup);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setShowPicPopup(false);
                }, 200);
              }}
              tabIndex={0}
            />
            {showPicPopup && (
              <div className="header_Company_dropdown">
                {/* <Button
                  text="A JOB"
                  text2="POST"
                  icon1="pinjob"
                  icon2="pinjob_ani"
                  className={"btn_def"}
                  onClick={() => {
                    window.location = "/post/create";
                  }}
                /> */}
                <Option
                  text="My Page"
                  rightElement={
                    <CustomIcon
                      iconName="link"
                      className={"company_nav_icon"}
                    />
                  }
                  iconName="home"
                  onClick={() => {
                    window.location = `/pinjob.me/${companyData?.user?.username}`;
                  }}
                />
                <Option
                  text="My JOBS"
                  rightElement={
                    <p className="company_nav_number">
                      {companyData?.posts_number}
                    </p>
                  }
                  iconName="logo_mark"
                  onClick={() => {
                    window.location = "/myjobs";
                  }}
                />
                <Option
                  text="Account"
                  iconName="setting"
                  onClick={() => {
                    window.location = "/company/update";
                  }}
                />
                <Option
                  text="Logout"
                  iconName="logout"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_role");
                    localStorage.removeItem("designerData");
                    localStorage.removeItem("companyData");
                    localStorage.removeItem("myJobs");
                    localStorage.removeItem("pins");

                    window.location = "/home";
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    
        ):(
      // * - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN-  */

      
      <div className="header_box jp_header">
        <div className="logo_box">
          <CustomIcon iconName="logo_mark" className="logo_mark" />
          <CustomIcon iconName="logo_type" />
        </div>
        <div className="nav_link_box to_left">
          <Nav name="ジョブ" 
          link="/home" 
          icon="jobs" 
          hoverIcon="jobs_ani" 
          />
          <Nav
            name="会社"
            link="/companies"
            icon="company"
            hoverIcon="company_ani"
            />
        </div>

        <div className="buttons_box, buttons_box_br1020 ">
          <Button
                text="を投稿"
                text2="JOB"
            icon1="pinjob"
            icon2="pinjob_ani"
            className={"hide_post_btn"}
            onClick={() => {
              window.location = "/post/create";
            }}
          />
          <div className="nav_link_box  hide_box_on_mobile">
            <Nav
              name="マイジョブ"
              icon="jobs"
              link="/myjobs"
              hoverIcon="pinjob"
              number={companyData?.posts_number}
            />
          </div>

          <div className="company_header_pic_cont">
            <CompanyPic
              className="company_header_pic"
              imgSrc={`http://178.16.142.189:8002${imgSrc}`}
              onClick={() => {
                setShowPicPopup(!showPicPopup);
              }}
              onBlur={() => {
                setTimeout(() => {
                  setShowPicPopup(false);
                }, 200);
              }}
              tabIndex={0}
            />
            {showPicPopup && (
              <div className="header_Company_dropdown jp_text">
                <Option
                  text="マイページ"
                  rightElement={
                    <CustomIcon
                      iconName="link"
                      className={"company_nav_icon"}
                    />
                  }
                  iconName="home"
                  onClick={() => {
                    window.location = `/pinjob.me/${companyData?.user?.username}`;
                  }}
                />
                <Option
                  text="マイジョブ"
                  rightElement={
                    <p className="company_nav_number">
                      {companyData?.posts_number}
                    </p>
                  }
                  iconName="logo_mark"
                  onClick={() => {
                    window.location = "/myjobs";
                  }}
                />
                <Option
                  text="アカウント"
                  iconName="setting"
                  onClick={() => {
                    window.location = "/company/update";
                  }}
                />
                <Option
                  text="ログアウト"
                  iconName="logout"
                  onClick={() => {
                    localStorage.removeItem("token");
                    localStorage.removeItem("user_role");
                    localStorage.removeItem("designerData");
                    localStorage.removeItem("companyData");
                    localStorage.removeItem("myJobs");
                    localStorage.removeItem("pins");

                    window.location = "/home";
                  }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
   


        )}

<div className="header-mockup"></div>
</>

  );
}
