import "../../styles/main.scss";
import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Nav from "../../compo/atoms/Nav";
import Button from "../../compo/atoms/Button";
// import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions";

function Footer() {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  return (
    <>
      {lang == "jp" ? (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL
        <div className="footer">
          <div className="footer_box">
            <div className="footer_top_box">
              <div className="footer_logo_box">
                <CustomIcon iconName="logo_mark" className="logo_mark" />
                <CustomIcon iconName="logo_type" />
              </div>
              <div className="footer_text_box">
                <div className="footer_text_bloc_box">
                  <h4>FOR EMPLOYERS</h4>
                  <div className="footer_links_box">
                    <Nav name="Signup" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav name="Login" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav
                      name="Pricing"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>

                <div className="footer_text_bloc_box">
                  <h4>FOR DESIGNERS</h4>
                  <div className="footer_links_box">
                    <Nav name="Login" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav name="Singup" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav
                      name="Browser Jobs"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>

                <div className="footer_text_bloc_box">
                  <h4>ABOUT PINJOB</h4>
                  <div className="footer_links_box">
                    <Nav name="Pinjob" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav
                      name="Contact"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="Recruit"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>

                <div className="footer_text_bloc_box">
                  <h4 className="footer_copy_box">
                    <span>©</span>
                    PINJOB 2024
                  </h4>
                  <div className="footer_links_box">
                    <Nav
                      name="Privacy Policy"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="Terms Of Use"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - -  */}
            <div className="footer_bottom_box">
              <div className="footer_sns_box">
                <Button
                  text={lang == "en" ? "GLOBAL JOBS" : "日本ジョブ"}
                  icon1={lang == "en" ? "en" : "fuji"}
                  className={"lang_btn"}
                  onClick={() => {
                    dispatch(changeLanguage(lang == "en" ? "jp" : "en"));
                  }}
                />
              </div>

              {/* <div className="footer_policy_box">
              <Nav
                link="/items"
                name="Privacy Policy"
                className={"footer_policy"}
              />
              |
              <Nav
                link="/items"
                name="Terms f Use"
                className={"footer_policy"}
              />
              |

            </div> */}
            </div>
          </div>
        </div>
      ) : (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN

        <div className="footer">
          <div className="footer_box">
            <div className="footer_top_box">
              <div className="footer_logo_box">
                <CustomIcon iconName="logo_mark" className="logo_mark" />
                <CustomIcon iconName="logo_type" />
              </div>
              <div className="footer_text_box jp_text">
                <div className="footer_text_bloc_box">
                  <h4>業社</h4>
                  <div className="footer_links_box">
                    <Nav
                      name="サインアップ"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="ログイン"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav name="金額" link="/items" className={"footer_nav"} />
                  </div>
                </div>

                <div className="footer_text_bloc_box">
                  <h4>デザイナー</h4>
                  <div className="footer_links_box">
                    <Nav
                      name="ログイン"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="サインアップ"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="Jobsを探す"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>

                <div className="footer_text_bloc_box">
                  <h4>PINJOB</h4>
                  <div className="footer_links_box">
                    <Nav name="Pinjob" link="/items" className={"footer_nav"} />
                    <span>|</span>
                    <Nav
                      name="お問い合わせ"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="リクルート"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>


                <div className="footer_text_bloc_box">
                  <h4 className="footer_copy_box">
                    <span>©</span>
                    PINJOB 2024
                  </h4>
                  <div className="footer_links_box jp_text">
                    <Nav
                      name="プライバシーポリシー"
                      link="/items"
                      className={"footer_nav"}
                    />
                    <span>|</span>
                    <Nav
                      name="利用規約"
                      link="/items"
                      className={"footer_nav"}
                    />
                  </div>
                </div>

              </div>
            </div>
            {/* - - - - - - - - - - - - - - - - - - - - -  */}
            <div className="footer_bottom_box">
              <div className="footer_sns_box">
                <Button
                  text={lang == "en" ? "GLOBAL JOBS" : "日本ジョブ"}
                  icon1={lang == "en" ? "global" : "jp"}
                  className={"lang_btn"}
                  onClick={() => {
                    dispatch(changeLanguage(lang == "en" ? "jp" : "en"));
                  }}
                />
              </div>
            </div>
            {/* - - - - - - - - - - - - - - - */}
          </div>
        </div>
      )}
      <div className="footer_mockup"></div>
    </>
  );
}

export default Footer;
