// NavigationLink.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom"; // Assuming you're using React Router for navigation
import CustomIcon from "../../data/IconCompo";

const Option = ({
  text,
  className,
  link,
  icon,
  hoverIcon,
  iconRight,
  iconName,
  rightElement,
  onClick,
}) => {
  return (
    <div
      className={`company_dropdown_link_cont ${className}`}
      onClick={() => {
        onClick();
      }}
    >
      <div className="company_dropdown_link_left">
        <CustomIcon
          iconName={iconName}
          className="company_dropdown_link_icon"
        />
        <p>{text}</p>
      </div>
      <p>{rightElement}</p>
    </div>
  );
};

export default Option;
