import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

const TextAreaSimple = ({
  focusedIconName,
  defaultIconName,
  placeholder,
  className,
  label,
  onChange,
  value,
  error,
  innerClass,
  maxLength,
  clearIcon,
  counter,
  pretext,
  suftext,
  customIcon,
  disable,
  letterCounter,
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (disable) {
      textareaRef.current.disabled = true;
    } else {
      textareaRef.current.disabled = false;
    }
  }, [disable]);

  return (
    <div className={`textarea-cont ${className} ${isFocused ? "focused" : ""}`}>
      <div className="label_box">
      {label && <label className="input-label">{label}</label>}
      {letterCounter && <letterCounter className="input-letterCounter">{letterCounter}</letterCounter>}
      </div>
      <div
        className={`textarea ${innerClass} ${error && "input_error"} ${
          disable ? "input-disabled" : ""
        }`}
      >
        {pretext && <p className="input-pretext">{pretext}</p>}
        <textarea
          placeholder={placeholder}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          onChange={(e) => onChange(e.target.value)}
          value={value ? value : ""}
          className={`${innerClass} ${pretext && "input-box-pretext"}`}
          ref={textareaRef}
          maxLength={maxLength}
        />
        {suftext && <p className="input-pretext">{suftext}</p>}
        {!customIcon ? (
          clearIcon && value ? (
            <CustomIcon
              iconName={value ? "clear" : defaultIconName}
              className={`input-icon ${isFocused ? "focused-icon" : ""} ${
                value ? "input-value-icon" : ""
              }`}
              onClick={() => {
                onChange("");
              }}
            />
          ) : (
            <CustomIcon
              iconName={isFocused ? focusedIconName : defaultIconName}
              className={`input-icon ${isFocused ? "focused-icon" : ""}`}
            />
          )
        ) : (
          customIcon
        )}
        {counter && counter}
      </div>
    </div>
  );
};

TextAreaSimple.propTypes = {
  focusedIconName: PropTypes.string.isRequired,
  defaultIconName: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string, // New prop for the label
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string,
  error: PropTypes.bool,
  innerClass: PropTypes.string,
  maxLength: PropTypes.number,
  clearIcon: PropTypes.bool,
  counter: PropTypes.element,
  pretext: PropTypes.string,
  suftext: PropTypes.string,
  customIcon: PropTypes.element,
  disable: PropTypes.bool,
};

TextAreaSimple.defaultProps = {
  placeholder: "Enter your text",
  className: "",
  label: "", // Default label is empty
  value: "",
  error: false,
  innerClass: "",
  maxLength: undefined,
  clearIcon: false,
  counter: null,
  pretext: "",
  suftext: "",
  customIcon: null,
  disable: false,
};

export default TextAreaSimple;
