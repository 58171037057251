import React, { useEffect, useState } from "react";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";

export default function InfoPopup({
  text,
  type,
  className,
  iconName,
  onClose,
}) {
  // console.log(iconName);
  return (
    <div
      className={`info-popup ${
        type == "error" ? "info-popup-error" : "info-popup-success"
      }`}
    >      {iconName && (
      <CustomIcon iconName={iconName} className={"info-popup-icon"} />
    )}
      {text && <p className="info-popup-text">{text}</p>}

      <div className="info-popup-close-box">
        <Button
          icon1="close"
          // icon2="close"
          className={"info-popup-close"}
          onClick={() => {
            onClose();
          }}
        />
      </div>
      
    </div>
  );
}
