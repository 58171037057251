import React, { useEffect, useState } from "react";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

export default function CompanyPic({ className, imgSrc, ...props }) {
  const [imageError, setImageError] = useState(false);
  useEffect(() => {
    if (imgSrc) {
      setImageError(false);
    }
  }, [imgSrc]);
  const handleImageError = () => {
    setImageError(true);
  };

  return (
    <div className={`avatar_box ${className}`} {...props}>
      {!imageError && <img src={imgSrc} onError={handleImageError} />}
      <div className="company_icon">
        <CustomIcon iconName="company" />
      </div>
      <div className="designer_icon">
        <CustomIcon iconName="designer" />
      </div>
    </div>
  );
}
