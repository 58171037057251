import "../../styles/items.scss";
import "../../styles/atoms.scss";
import "../../styles/vars.scss";
import CustomIcon from "../../data/IconCompo";
import { useDispatch, useSelector } from "react-redux";

export default function TopPageIntro3({ className, iconName }) {
  const lang = useSelector((state) => state.lang);

  return (
    <div className="top_intro_box">
      <div className="top_intro_content_box-2">
        <div className="top_intro_right">
          <CustomIcon iconName={iconName} className="illus illus-2" />
        </div>
        {lang === "jp" ? (
          <div className="top_intro_left">
            {/* <h7>PINJOB</h7>
            <h8>
              DESIGNERS <span>ONLY</span>
            </h8>
            <span>JOB BOARD</span> */}
          </div>
        ) : (
          <div className="top_intro_left jp">
            {/* <h8>デザイナー</h8>
            <h8>特化した</h8>
            <span>ジョブボート</span> */}
          </div>
        )}
      </div>
    </div>
  );
}
