import "../styles/main.scss";
import "../styles/textarea.scss";
import PageTitle from "../compo/atoms/PageTitle";
import Separator from "../compo/atoms/Separator";
import HeaderSimple from "../compo/items/HeaderSimple";
import TopPageIntro3 from "../compo/items/TopPageIntro3";
import CheckButton from "../compo/atoms/CheckBtn";
import ButtonDef from "../compo/atoms/Button";
import { useState } from "react";
import pirsch from "../Analytics"

function EntryPage() {
  const [isChekced, setIsChecked] = useState(false);
  return (
    <>
      <TopPageIntro3 iconName="illust_19" className="entry-form-illust" />

      <div className="entry_page_section_hero">
        <div className="entry_page_section_box">
          <PageTitle title="PINJOBの利用規約:" className={"title"} />
          <Separator />
          <div className="terms ql-editor">
            <p>
              PINJOBへようこそ。デザイナー専用の求人情報サービスです。本サービスをご利用になる前に、以下の利用規約をよくお読みいただき、ご理解ください。
            </p>
            <ul>
              <li>
                <strong>対象職種:</strong><br/>
                PINJOBはデザインに関連する求人情報のみを受け付けています。プロダクトデザイナー、UX/UIデザイナー、UXデザイナー、UIデザイナー、グラフィックデザイナーのポジションのみが受け付けられます。
              </li>
              <li>
                <strong>デザイナー専用:</strong><br/>
                当サービスではデザイナー向けの求人情報のみを掲載しています。対象職種は、プロダクトデザイナー、UX/UIデザイナー、UXデザイナー、UIデザイナー、グラフィックデザイナーなどデザイナー向けのみで、その他の職種の求人は受け付けておりません。
              </li>
              <li>
                <strong>企業による直接採用案件の投稿:</strong><br/>
                {/* 企業からの直接の求人投稿のみを受け付けています。人材紹介会社、派遣会社、その他の類似する企業からの投稿は禁止されています。 */}
                企業が直接採用活動をしている求人案件のみを受け付けています。そのため人材紹介会社、派遣会社、その他の類似する企業からの投稿はできません。
              </li>
              <li>
                <strong>法令遵守:</strong><br/>
                違法な求人情報や犯罪に関連する職種は厳禁です。
              </li>
              <li>
                <strong>給与基準:</strong><br/>
                全ての求人情報は、日本の標準的な最低賃金を満たす給与を提供する必要があります。
              </li>
              <li>
                <strong>正確性:</strong><br/>
                偽の企業情報、誤ったロゴ、または企業の性質に関する誤解を招く情報は許可されません。
              </li>
            </ul>
            <p>
            違反行為を繰り返す場合は、返金・その他の補償権利を有さずにサービスの停止するとともに、処置させていただきます。
              <br />
              PINJOBのサービスをご利用いただく際には、これらの利用規約に同意していただくことになります。
            </p>
            <p>
              ご質問やさらなる説明が必要な場合は、お気軽にお問い合わせください。
              <br />
              PINJOBをご利用いただき、誠にありがとうございます。
              <br />
              優れたデザイナーと素晴らしい機会をつなげることを楽しみにしています。
            </p>
          </div>
          <div className="entry_terms_actions_box">
            <CheckButton
              iconUnchecked="check_off"
              iconChecked="check_on"
              label="以上の利用規約に同意します。"
              setIsChecked={(checked) => setIsChecked(checked)}
              isChecked={isChekced}
            />

            <ButtonDef
              icon1="right_arrow"
              icon2={isChekced && "right_arrow_ani"}
              className={
                isChekced
                  ? "terms-page-unlock-button"
                  : "terms-page-lock-button"
              }
              text="エントリーフォームに進む"
              onClick={() => {
                pirsch("GO TO Entry Form");
                if (isChekced) {
                  window.location = "entry-form";
                  localStorage.setItem("IsTermsAccepted", true);

                }
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default EntryPage;
