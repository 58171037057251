import React from "react";
import flags from "../atoms/Flags";
import countries from "world-countries";

const getAlphaTwoCode = (code) => {
  const uc = String(code).toUpperCase();
  const country = countries.find(
    // eslint-disable-next-line eqeqeq
    (c) => c.cca2 === uc || c.ccn3 === uc || c.cca3 == uc
  );
  return (country && country.cca2) || code;
};

const Flag = (props) => {
  const { code, fallback = null, ...rest } = props;
  if (!code) return fallback;
  const alphaTwo = getAlphaTwoCode(code);
  const flag = flags["flag_" + alphaTwo?.replace("-", "_")];
  return flag ? <img {...rest} src={flag} alt="flag" /> : fallback;
};

export default Flag;
