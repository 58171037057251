import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

const CheckButton = ({
  iconUnchecked,
  iconChecked,
  label,
  className,
  onChange,
  value,
  setIsChecked,
  isChecked = false,
}) => {
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  return (
    <div
      className={`check_radio_box ${className}`}
      onClick={handleCheckboxChange}
    >
      <CustomIcon
        iconName={isChecked ? iconChecked : iconUnchecked}
        className={isChecked ? "checked" : "unchecked"}
      />
      {label}
    </div>
  );
};

CheckButton.propTypes = {
  iconUnchecked: PropTypes.string.isRequired,
  iconChecked: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

CheckButton.defaultProps = {
  className: "",
};

export default CheckButton;
