// RadioButton.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomIcon from "../../data/IconCompo";

const PlanCard = ({
  value,
  iconUnchecked,
  iconChecked,
  planTitle,
  planPrice,
  planText,
  className,
}) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = () => {
    setSelectedValue(value);
  };

  return (
    <div className={`check_radio_box ${className}`} onClick={handleRadioChange}>
      <div className="plan_text_box">
        
        <div className="plan_text">
        <span>{planTitle}</span>
        <span>{planText}</span>
        </div>
        {planPrice}
      </div>
      <CustomIcon
        iconName={selectedValue === value ? iconChecked : iconUnchecked}
        className={selectedValue === value ? "checked" : "unchecked"}
      />
    </div>
  );
};

PlanCard.propTypes = {
  value: PropTypes.any.isRequired,
  iconUnchecked: PropTypes.string.isRequired,
  iconChecked: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

PlanCard.defaultProps = {
  className: "",
};

export default PlanCard;
