// NavigationLink.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";
import Button from "../../compo/atoms/Button";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import PopupTab from "../atoms/PopupTab";
import { CreateDesigner, Signin } from "../../services/User";
import { UserLogin, UserSignup } from "../../validation";

const PopupCardCompany = ({
  title,
  text,
  titleNumber,
  className,
  showInfoPopup,
  showPopup,
  onClose,
}) => {
  const [login, setLogin] = useState(true);
  const [data, setData] = useState({});
  const [errors, setErrors] = useState({});

  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };
  const handleSubmit = () => {
    // Assuming you have the necessary data to send in your request
    if (login) {
      setErrors(UserLogin(data));
      let x = UserLogin(data);
      // Assuming you have the necessary data to send in your request
      if (Object.keys(x).length === 0) {
        Signin(data?.login_email, "company")
          .then((response) => {
            if (response?.data?.message) {
              setData();
              showPopup(
                "DONE",
                "The post has been successfully created and published!"
              );
              onClose();
            }
          })
          .catch((error) => {
            showInfoPopup("error", error?.response?.data?.error);
            setErrors({
              ...errors,
              login_email: error?.response?.data?.error,
            });
          });
      } else {
        if (Object.keys(x).length === 1) {
          showInfoPopup("error", x[Object.keys(x)[0]]);
        } else if (Object.keys(x).length >= 1) {
          showInfoPopup("error", "Please fill required fields with valid data");
        }
      }
    }
  };
  return (
    <div className={`popup_lightbox ${className}`}>
      <div className="popup_box">
        <>
          <div className="popup_close_box">
            <Button
              icon1="close"
              icon2="close"
              className={"btn_c_icn"}
              onClick={() => {
                onClose();
              }}
            />
          </div>
          <div className="popup_title_box">
            FOR EMPLOYERS
            <CustomIcon iconName="company" />
          </div>
        </>

        <div className="popup_content_box">
          {
            <PopupTab
              tab1Name="Login"
              tab2Name="Signup"
              setLogin={(res) => {
                setLogin(res);
              }}
            />
          }
          {/* <CustomIcon iconName="illust_10" className="illus" /> */}
          {/* - - - - - - - - - - - - - - - -  SIGNUP - - - - - - - - - - - - - -  */}
          {!login && (
            <div className="form_box">
                        <CustomIcon iconName="illust_10" className="illus" />

              <PageTitle
                sectionTitle="Employers Signup"
                text="Post your first job to signup and create your account"
              />
              <>
                {" "}
                <Button
                  text="POST A JOB"
                  icon1="pinjob"
                  icon2="pinjob_ani"
                  onClick={() => {
                    window.location = "/signup";
                  }}
                />
                <Button
                  text="PRICING"
                  icon1="money"
                  icon2="money_ani"
                  className="btn_c_grey"
                />
              </>
            </div>
          )}

          {/* - - - - - - - - - - - - - - - -  LOGIN - - - - - - - - - - - - - -  */}
          <div className="form_box">
            {login && (
              <>
                {" "}
                <CustomIcon iconName="illust_07" className="illus" />

                <PageTitle
                  sectionTitle="Employers Login"
                  text="Receive your magic login link by email."
                />
              </>
            )}
            <div className="input_box_cont">
              {/*!login && (
                <Input
                  placeholder="Name"
                  className="input_def "
                  onChange={(e) => handleChange("name", e)}
                  value={data?.name}
                  error={errors?.name}
                  defaultIconName={"mail"}
                />
              )*/}
              {login && (
                <>
                  <Input
                    focusedIconName="M"
                    defaultIconName="mail"
                    placeholder="Email"
                    className="input_def"
                    label=""
                    onChange={(e) => handleChange("login_email", e)}
                    value={data?.login_email}
                    error={errors?.login_email}
                  />

                  <Button
                    text="Send"
                    icon1="send"
                    icon2="send_ani"
                    onClick={() => {
                      handleSubmit();
                    }}
                  />
                </>
              )}
            </div>
          </div>
          {/* - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - */}
        </div>
      </div>
    </div>
  );
};

export default PopupCardCompany;
