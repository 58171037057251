import "../../styles/main.scss";
import "../../styles/temp.scss";
import PageTitle from "../atoms/PageTitle";
import Input from "../atoms/Input";
import TextArea from "../atoms/TextArea";
import Button from "../atoms/Button";
import Select from "../atoms/Select";
import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import TextAreaSimple from "../atoms/TextAreaSimple";
import { contactFormValidation } from "../../validation";
import { BACKEND_URL } from "../../config";
import axios from "axios";

export default function ContactForm({
  countries = [],
  showInfoPopup,
  showPopup,
}) {
  const lang = useSelector((state) => state.lang);
  const [errors, setErrors] = useState({});
  const formRef = useRef(null);
  const [data, setData] = useState({});
  const [desc, setDesc] = useState();
  const handleChange = async (element, dataP) => {
    const newData = { ...data, [`${element}`]: dataP };
    setData(newData);
    console.log(newData);
    if (errors[element]) {
      errors[element] = false;
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    // Assuming you have the necessary data to send in your request
    setErrors(contactFormValidation(data, lang));
    let x = contactFormValidation(data, lang);
    // Assuming you have the necessary data to send in your request
    if (Object.keys(x).length === 0) {
      showInfoPopup("success", "Connecting!...", 4000, "right_arrow_ani");

      axios
        .post(
          `${BACKEND_URL}/api/contact`,
          {
            name: data?.name,
            email: data?.email,
            phone: data?.phone,
            desc: data?.desc,
            subject_title: data?.subject_title?.label,
            company: data?.company,
          },
          {}
        )
        .then((res) => {
          setData({});
          setDesc({});

          showInfoPopup(false);
          showPopup(
            lang === "en" ? "DONE" : "THANK YOU!",
            lang === "en"
              ? "The post has been successfully created and published!"
              : "お問い合わせいただき、ありがとうございます! \nお問い合わせ内容に応じて、迅速に対応いたしますので、\n少々お待ちください。"
          );
        })
        .catch((err) => {
          console.log(err);
          showInfoPopup("error", "Please try again later");
        });
    } else {
      if (Object.keys(x).length === 1) {
        showInfoPopup("error", x[Object.keys(x)[0]]);
      } else if (Object.keys(x).length >= 1) {
        showInfoPopup(
          "error",
          lang === "en"
            ? "Please fill required fields with valid data"
            : "必須項目を適切に入力してください。 "
        );
      }
    }
  };
  useEffect(() => {
    if (data?.remote?.value == "remote") {
      setErrors((prev) => {
        if (prev?.country || prev?.city) {
          delete prev?.country;
          delete prev?.city;
        }
        return { ...prev };
      });
      setData((prev) => {
        if (prev?.country || prev?.city) {
          delete prev?.country;
          delete prev?.city;
        }
        return { ...prev };
      });
    }
  }, [data?.remote?.value]);

  return (
    // ----------------------------------------------------------------------------------
    <>
      {lang == "en" ? (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - GLOBAL
        <div className="page_Section_contact_box contactform_box">
          <PageTitle
            sectionTitle="JOB INFOOOOOOO"
            text="Please be as detailed as possible describing the job opening."
          />
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="Job Title"
              options={[
                { label: "Product Designer", value: "Product Designer" },
                { label: "UXUI Designer", value: "UXUI Designer" },
                { label: "UX Designer", value: "UX Designer" },
                { label: "UI Designer", value: "UI Designer" },
                { label: "Graphic Designer", value: "Graphic Designer" },
                { label: "Animation Designer", value: "Animation Designer" },
                { label: "Branding Designer", value: "Branding Designer" },
              ]}
              setSelectedOption={(e) => handleChange("subject_title", e)}
              selectedOption={data?.subject_title}
              error={errors?.subject_title}
            />
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" signup_post_input"
              label="Job Type"
              options={[
                { label: "Full Time", value: "Full Time" },
                { label: "Part Time", value: "Part Time" },
                { label: "Contractor", value: "contractor" },
                { label: "Internship", value: "Internship" },
                { label: "Temporary", value: "Temporary" },
              ]}
              setSelectedOption={(e) => handleChange("jobType", e)}
              selectedOption={data?.jobType}
              error={errors?.jobType}
            />
          </div>
          <div className="job_info_inputs_box">
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" workspace_input"
              label="Workplace type"
              options={[
                { label: "Remote", value: "remote" },
                { label: "Hybrid", value: "hybrid" },
                { label: "Onsite", value: "on-site" },
              ]}
              selectedOption={data?.remote}
              error={errors?.remote}
              setSelectedOption={(e) => handleChange("remote", e)}
            />
            <Select
              placeholder=""
              defaultIconName="drop_down"
              focusedIconName="drop_up"
              className=" "
              label="Country"
              options={countries}
              flag={true}
              selectedOption={data?.country}
              error={errors?.country}
              setSelectedOption={(e) => handleChange("country", e)}
              disable={data?.remote?.value == "remote" ? true : false}
              search={true}
              clearIcon={true}
            />

            <Input
              placeholder="City"
              className="input_def signup_post_input"
              label="City"
              onChange={(e) => handleChange("city", e)}
              value={data?.city}
              error={errors?.city}
              disable={data?.remote?.value == "remote" ? true : false}
            />
          </div>
          <div className="job_info_inputs_box">
            <Input
              placeholder="Minimum salary"
              className="input_def salary_input_cont "
              innerClass="salary_input"
              label="Minimum salary"
              onChange={(e) => {
                if (!isNaN(e)) {
                  handleChange("minimum_salary", e);
                }
              }}
              value={data?.minimum_salary}
              error={errors?.minimum_salary}
            />
            <Input
              placeholder="Maximum salary "
              className="input_def salary_input_cont "
              innerClass="salary_input"
              label="Maximum salary"
              onChange={(e) => {
                if (!isNaN(e)) {
                  handleChange("maximum_salary", e);
                }
              }}
              value={data?.maximum_salary}
              error={errors?.maximum_salary}
            />
          </div>
          <Input
            placeholder="Application URL or Public Email"
            className="input_def "
            label="Application URL or Public Email"
            onChange={(e) => handleChange("endpoint", e)}
            value={data?.endpoint}
            error={errors?.endpoint}
          />

          <TextArea
            label={"Job Description"}
            // placeholder=""
            onChange={(e) => {
              setDesc(e);
            }}
            value={desc}
            error={errors?.job_description}
          />
          <div className="signup_save_button_cont">
            <Button
              text="Save & Subscribe"
              icon1="cart"
              icon2="cart"
              className="signup_save_button"
            />
          </div>
        </div>
      ) : (
        // - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - JAPAN

        <div className=" contact-form-box">
          <PageTitle
            sectionTitle="お問い合わせ!"
            text="サービス内容やロードマップについてご質問等ございましたら、お気軽にお問い合わせください。"
          />
          <form onSubmit={handleSubmit} ref={formRef} method="post">
            <div className="contact_inputs_box">
              <Input
                placeholder=""
                className="input_def "
                label="お名前"
                onChange={(e) => handleChange("name", e)}
                value={data?.name}
                error={errors?.name}
              />
              <Input
                placeholder=""
                className="input_def "
                label="メールアドレス"
                onChange={(e) => handleChange("email", e)}
                value={data?.email}
                error={errors?.email}
              />
              <Input
                placeholder=""
                className="input_def "
                label="会社名"
                onChange={(e) => handleChange("company", e)}
                value={data?.company}
                error={errors?.company}
              />
              <Input
                placeholder=""
                className="input_def "
                label="電話番号（オプション）"
                onChange={(e) => {
                  if (/^-?\d*\.?\d+$/.test(e) || e.length == 0) {
                    handleChange("phone", e);
                  }
                }}
                value={data?.phone}
                error={errors?.phone}
              />
            </div>

            <div className="contact_inputs_box">
              <Select
                placeholder=""
                defaultIconName="drop_down"
                focusedIconName="drop_up"
                className="signup_post_input"
                label="お問い合わせの件名"
                options={[
                  {
                    label: "サービスに関するお問い合わせ",
                    value: "Service Inquiry",
                  },
                  { label: "求人に関するお問い合わせ", value: "Job Inquiry" },
                  { label: "提案やご意見", value: "Suggestions or Feedback" },
                  {
                    label: "ビジネスパートナーシップについて",
                    value: "Business Partnership Inquiry",
                  },
                  {
                    label: "スポンサーシップに関するお問い合わせ",
                    value: "Sponsorship Inquiry",
                  },
                ]}
                setSelectedOption={(e) => handleChange("subject_title", e)}
                selectedOption={data?.subject_title}
                error={errors?.subject_title}
              />

              <TextAreaSimple
                className=""
                label={"メッセージ"}
                placeholder=""
                onChange={(e) => {
                  handleChange("desc", e);
                }}
                value={data?.desc}
                error={errors?.desc}
                textStyle={false}
                innerClass={"textarea-input-cont"}
              />
            </div>

            <div className="signup_save_button_cont">
              <Button
                text="送信"
                icon1="send"
                icon2="send_ani"
                className="signup_save_button"
                onClick={() => {}}
              />
            </div>
          </form>
        </div>
      )}
    </>

    // -----------------------------------------------------------------------------
  );
}
