import React, { useState } from "react";
import "../../styles/main.scss";
import CustomIcon from "../../data/IconCompo";

export default function ButtonDef({
  text,
  text2,
  className,
  icon1,
  icon2, // Make icon2 optional
  onClick,
}) {
  const [isHovered, setIsHovered] = useState(false);

  // Join multiple classes into a single string
  const combinedClassName = Array.isArray(className)
    ? className.join(" ")
    : className;

  return (
    <button
      className={`btn_def ${combinedClassName}`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={(e) => {
        onClick(e);
      }}
    >
      {text2} {text && <span>{text}</span>}
      {/* Check if icon2 is provided, if not, use icon1 */}
      <CustomIcon iconName={isHovered && icon2 ? icon2 : icon1} />
    </button>
  );
}
