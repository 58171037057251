import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeLanguage } from "../../redux/actions";

export default function HeaderSimple({
  text,
  className,
  showUserPopup,
  showCompanyPopup,
}) {
  const lang = useSelector((state) => state.lang);
  const dispatch = useDispatch();
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY >= 1) {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "88px";
          el.style.borderBottom = "2px solid #00000010";
        });

        // document.querySelector(".header-mockup").style.display = "block";
      } else {
        document.querySelectorAll(".header_box").forEach((el) => {
          el.style.height = "120px";
          el.style.borderBottom = "1px solid transparent";
        });
      }
    };
    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <div className="header_box">
        <div
          className="logo_box"
          onClick={() => {
            window.location = "/entry-page";
          }}
        >
          <CustomIcon iconName="logo_mark" className="logo_mark" />
          <CustomIcon iconName="logo_type" />
        </div>
      </div>
      <div className="header-mockup"></div>
    </>
  );
}
