
import React from 'react';

import { ReactComponent as Image } from '../assets/icons/Image.svg';
import { ReactComponent as add } from '../assets/icons/add.svg';
import { ReactComponent as back } from '../assets/icons/back.svg';
import { ReactComponent as blocked } from '../assets/icons/blocked.svg';
import { ReactComponent as bold } from '../assets/icons/bold.svg';
import { ReactComponent as btn_bg } from '../assets/icons/btn_bg.svg';
import { ReactComponent as btn_bg_hover } from '../assets/icons/btn_bg_hover.svg';
import { ReactComponent as cal } from '../assets/icons/cal.svg';
import { ReactComponent as cart } from '../assets/icons/cart.svg';
import { ReactComponent as check_off } from '../assets/icons/check_off.svg';
import { ReactComponent as check_on } from '../assets/icons/check_on.svg';
import { ReactComponent as clear } from '../assets/icons/clear.svg';
import { ReactComponent as clear_ani } from '../assets/icons/clear_ani.svg';
import { ReactComponent as clicks } from '../assets/icons/clicks.svg';
import { ReactComponent as close } from '../assets/icons/close.svg';
import { ReactComponent as company } from '../assets/icons/company.svg';
import { ReactComponent as company_ani } from '../assets/icons/company_ani.svg';
import { ReactComponent as designer } from '../assets/icons/designer.svg';
import { ReactComponent as designer_ani } from '../assets/icons/designer_ani.svg';
import { ReactComponent as dots_on } from '../assets/icons/dots_on.svg';
import { ReactComponent as drop } from '../assets/icons/drop.svg';
import { ReactComponent as drop_down } from '../assets/icons/drop_down.svg';
import { ReactComponent as drop_up } from '../assets/icons/drop_up.svg';
import { ReactComponent as edit } from '../assets/icons/edit.svg';
import { ReactComponent as edit_ani } from '../assets/icons/edit_ani.svg';
import { ReactComponent as employer } from '../assets/icons/employer.svg';
import { ReactComponent as employer_ani } from '../assets/icons/employer_ani.svg';
import { ReactComponent as empty } from '../assets/icons/empty.svg';
import { ReactComponent as empty_on } from '../assets/icons/empty_on.svg';
import { ReactComponent as en } from '../assets/icons/en.svg';
import { ReactComponent as error } from '../assets/icons/error.svg';
import { ReactComponent as eye_off } from '../assets/icons/eye_off.svg';
import { ReactComponent as facebook_line } from '../assets/icons/facebook_line.svg';
import { ReactComponent as flag } from '../assets/icons/flag.svg';
import { ReactComponent as fuji } from '../assets/icons/fuji.svg';
import { ReactComponent as global } from '../assets/icons/global.svg';
import { ReactComponent as hand2 } from '../assets/icons/hand2.svg';
import { ReactComponent as hand4 } from '../assets/icons/hand4.svg';
import { ReactComponent as home } from '../assets/icons/home.svg';
import { ReactComponent as humb } from '../assets/icons/humb.svg';
import { ReactComponent as illus_login_user } from '../assets/icons/illus_login_user.svg';
import { ReactComponent as illus_pricing } from '../assets/icons/illus_pricing.svg';
import { ReactComponent as illust_00 } from '../assets/icons/illust_00.svg';
import { ReactComponent as illust_01 } from '../assets/icons/illust_01.svg';
import { ReactComponent as illust_02 } from '../assets/icons/illust_02.svg';
import { ReactComponent as illust_03 } from '../assets/icons/illust_03.svg';
import { ReactComponent as illust_04 } from '../assets/icons/illust_04.svg';
import { ReactComponent as illust_05 } from '../assets/icons/illust_05.svg';
import { ReactComponent as illust_06 } from '../assets/icons/illust_06.svg';
import { ReactComponent as illust_07 } from '../assets/icons/illust_07.svg';
import { ReactComponent as illust_08 } from '../assets/icons/illust_08.svg';
import { ReactComponent as illust_09 } from '../assets/icons/illust_09.svg';
import { ReactComponent as illust_10 } from '../assets/icons/illust_10.svg';
import { ReactComponent as illust_11 } from '../assets/icons/illust_11.svg';
import { ReactComponent as illust_12 } from '../assets/icons/illust_12.svg';
import { ReactComponent as illust_13 } from '../assets/icons/illust_13.svg';
import { ReactComponent as illust_14 } from '../assets/icons/illust_14.svg';
import { ReactComponent as illust_15 } from '../assets/icons/illust_15.svg';
import { ReactComponent as illust_16 } from '../assets/icons/illust_16.svg';
import { ReactComponent as illust_17 } from '../assets/icons/illust_17.svg';
import { ReactComponent as illust_18 } from '../assets/icons/illust_18.svg';
import { ReactComponent as illust_19 } from '../assets/icons/illust_19.svg';
import { ReactComponent as illust_20 } from '../assets/icons/illust_20.svg';
import { ReactComponent as instagram_line } from '../assets/icons/instagram_line.svg';
import { ReactComponent as italic } from '../assets/icons/italic.svg';
import { ReactComponent as jobs } from '../assets/icons/jobs.svg';
import { ReactComponent as jobs_ani } from '../assets/icons/jobs_ani.svg';
import { ReactComponent as jp } from '../assets/icons/jp.svg';
import { ReactComponent as left_arrow } from '../assets/icons/left_arrow.svg';
import { ReactComponent as link } from '../assets/icons/link.svg';
import { ReactComponent as linkedin_line } from '../assets/icons/linkedin_line.svg';
import { ReactComponent as list } from '../assets/icons/list.svg';
import { ReactComponent as list_number } from '../assets/icons/list_number.svg';
import { ReactComponent as login } from '../assets/icons/login.svg';
import { ReactComponent as logo_mark } from '../assets/icons/logo_mark.svg';
import { ReactComponent as logo_type } from '../assets/icons/logo_type.svg';
import { ReactComponent as logout } from '../assets/icons/logout.svg';
import { ReactComponent as mail } from '../assets/icons/mail.svg';
import { ReactComponent as mail_ani } from '../assets/icons/mail_ani.svg';
import { ReactComponent as map } from '../assets/icons/map.svg';
import { ReactComponent as match } from '../assets/icons/match.svg';
import { ReactComponent as money } from '../assets/icons/money.svg';
import { ReactComponent as money_ani } from '../assets/icons/money_ani.svg';
import { ReactComponent as ok } from '../assets/icons/ok.svg';
import { ReactComponent as pin_ani } from '../assets/icons/pin_ani.svg';
import { ReactComponent as pin_in } from '../assets/icons/pin_in.svg';
import { ReactComponent as pin_in_ani } from '../assets/icons/pin_in_ani.svg';
import { ReactComponent as pin_off } from '../assets/icons/pin_off.svg';
import { ReactComponent as pinjob } from '../assets/icons/pinjob.svg';
import { ReactComponent as pinjob_ani } from '../assets/icons/pinjob_ani.svg';
import { ReactComponent as radio_off } from '../assets/icons/radio_off.svg';
import { ReactComponent as radio_on } from '../assets/icons/radio_on.svg';
import { ReactComponent as right_arrow } from '../assets/icons/right_arrow.svg';
import { ReactComponent as right_arrow_ani } from '../assets/icons/right_arrow_ani.svg';
import { ReactComponent as save } from '../assets/icons/save.svg';
import { ReactComponent as search } from '../assets/icons/search.svg';
import { ReactComponent as search_ani } from '../assets/icons/search_ani.svg';
import { ReactComponent as send } from '../assets/icons/send.svg';
import { ReactComponent as send_ani } from '../assets/icons/send_ani.svg';
import { ReactComponent as setting } from '../assets/icons/setting.svg';
import { ReactComponent as signup } from '../assets/icons/signup.svg';
import { ReactComponent as stop } from '../assets/icons/stop.svg';
import { ReactComponent as tag } from '../assets/icons/tag.svg';
import { ReactComponent as text_big } from '../assets/icons/text_big.svg';
import { ReactComponent as text_small } from '../assets/icons/text_small.svg';
import { ReactComponent as timer } from '../assets/icons/timer.svg';
import { ReactComponent as top_bg } from '../assets/icons/top_bg.svg';
import { ReactComponent as trash } from '../assets/icons/trash.svg';
import { ReactComponent as trash_ani } from '../assets/icons/trash_ani.svg';
import { ReactComponent as twitter } from '../assets/icons/twitter.svg';
import { ReactComponent as twitter_line } from '../assets/icons/twitter_line.svg';
import { ReactComponent as view } from '../assets/icons/view.svg';
import { ReactComponent as views } from '../assets/icons/views.svg';

const iconComponents = {
  'Image': Image,
  'add': add,
  'back': back,
  'blocked': blocked,
  'bold': bold,
  'btn_bg': btn_bg,
  'btn_bg_hover': btn_bg_hover,
  'cal': cal,
  'cart': cart,
  'check_off': check_off,
  'check_on': check_on,
  'clear': clear,
  'clear_ani': clear_ani,
  'clicks': clicks,
  'close': close,
  'company': company,
  'company_ani': company_ani,
  'designer': designer,
  'designer_ani': designer_ani,
  'dots_on': dots_on,
  'drop': drop,
  'drop_down': drop_down,
  'drop_up': drop_up,
  'edit': edit,
  'edit_ani': edit_ani,
  'employer': employer,
  'employer_ani': employer_ani,
  'empty': empty,
  'empty_on': empty_on,
  'en': en,
  'error': error,
  'eye_off': eye_off,
  'facebook_line': facebook_line,
  'flag': flag,
  'fuji': fuji,
  'global': global,
  'hand2': hand2,
  'hand4': hand4,
  'home': home,
  'humb': humb,
  'illus_login_user': illus_login_user,
  'illus_pricing': illus_pricing,
  'illust_00': illust_00,
  'illust_01': illust_01,
  'illust_02': illust_02,
  'illust_03': illust_03,
  'illust_04': illust_04,
  'illust_05': illust_05,
  'illust_06': illust_06,
  'illust_07': illust_07,
  'illust_08': illust_08,
  'illust_09': illust_09,
  'illust_10': illust_10,
  'illust_11': illust_11,
  'illust_12': illust_12,
  'illust_13': illust_13,
  'illust_14': illust_14,
  'illust_15': illust_15,
  'illust_16': illust_16,
  'illust_17': illust_17,
  'illust_18': illust_18,
  'illust_19': illust_19,
  'illust_20': illust_20,
  'instagram_line': instagram_line,
  'italic': italic,
  'jobs': jobs,
  'jobs_ani': jobs_ani,
  'jp': jp,
  'left_arrow': left_arrow,
  'link': link,
  'linkedin_line': linkedin_line,
  'list': list,
  'list_number': list_number,
  'login': login,
  'logo_mark': logo_mark,
  'logo_type': logo_type,
  'logout': logout,
  'mail': mail,
  'mail_ani': mail_ani,
  'map': map,
  'match': match,
  'money': money,
  'money_ani': money_ani,
  'ok': ok,
  'pin_ani': pin_ani,
  'pin_in': pin_in,
  'pin_in_ani': pin_in_ani,
  'pin_off': pin_off,
  'pinjob': pinjob,
  'pinjob_ani': pinjob_ani,
  'radio_off': radio_off,
  'radio_on': radio_on,
  'right_arrow': right_arrow,
  'right_arrow_ani': right_arrow_ani,
  'save': save,
  'search': search,
  'search_ani': search_ani,
  'send': send,
  'send_ani': send_ani,
  'setting': setting,
  'signup': signup,
  'stop': stop,
  'tag': tag,
  'text_big': text_big,
  'text_small': text_small,
  'timer': timer,
  'top_bg': top_bg,
  'trash': trash,
  'trash_ani': trash_ani,
  'twitter': twitter,
  'twitter_line': twitter_line,
  'view': view,
  'views': views,
};

const CustomIcon = ({ iconName, ...props }) => {
  const SelectedIcon = iconComponents[iconName];
  if (!SelectedIcon) {
    console.warn(`Icon "${iconName}" not found`);
    return null; // Or a default icon
  }

  return <SelectedIcon {...props} />;
};

export default CustomIcon;
