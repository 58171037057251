import "../styles/main.scss";
import CustomIcon from "../data/IconCompo";
import Header from "../compo/items/Header";
import ListSearch from "../compo/templates/ListSearch";
import TopPageIntro from "../compo/items/TopPageIntro";
import axios from "axios";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import { BACKEND_URL } from "../config";

function PageView({
  setUserToken,
  setUserRole,
  setDesignerData,
  designerData,
  showUserPopup,
}) {
  const [filter, setFilter] = useState({});
  const [data, setData] = useState(
    localStorage.getItem("jobPosts")
      ? JSON.parse(localStorage.getItem("jobPosts")).slice(1)
      : []
  );
  const [next, setNext] = useState();
  const [prev, setPrev] = useState();
  const [jobsNo, setJobsNo] = useState(
    localStorage.getItem("jobPosts")
      ? JSON.parse(localStorage.getItem("jobPosts"))[0]
      : null
  );
  const { password, username } = useParams();
  const [userId, setUserId] = useState();
  // get pins every time make a pagination request
  useEffect(() => {}, []);
  useEffect(() => {
    if (localStorage.getItem("token")) {
      setUserId(jwtDecode(localStorage.getItem("token"))?.user_id);
      axios
        .get(
          `${BACKEND_URL}/api/designer/${
            jwtDecode(localStorage.getItem("token"))?.user_id
          }`
        )
        .then((response) => {
          if (response.data) {
            setDesignerData(response.data);
          }
        })
        .catch((error) => {
          setDesignerData(false);
          console.error(error); // handle errors here
        });
    }
  }, [next, prev]);

  useEffect(() => {
    const debounceTimeout = setTimeout(() => {
      if (filter) {
        search(filter);
      }
    }, 300); // Adjust the delay as needed (e.g., 1000 milliseconds = 1 second)

    return () => clearTimeout(debounceTimeout);
  }, [filter]);

  const search = (filterData) => {
    axios
      .get(`${BACKEND_URL}/api/posts`, {
        params: {
          type: filterData?.jobType?.value,
          title: filterData?.jobTitle?.value,
          country: filterData?.country?.search,
          search: filterData?.search,
          salary: filterData?.salary,
          remote: [filterData?.remote, filterData?.hybrid, filterData?.on_site],
          currency: filterData?.currency?.value,
          prefecture: filterData?.prefecture?.search,
        },
      })
      .then((response) => {
        if (response.data) {
          setData(response.data.results);
          localStorage.setItem(
            "jobPosts",
            JSON.stringify([response.data.count, ...response.data.results])
          );
        }
        setNext(response.data.next);
        setPrev(response.data.previous);
        setJobsNo(response.data.count);
      })
      .catch((error) => {
        console.error(error); // handle errors here
      });
  };
  const handlePagination = (link) => {
    axios
      .get(link, {
        params: {
          type: filter?.jobType?.value,
          title: filter?.jobTitle?.value,
          country: filter?.country?.search,
          search: filter?.search,
          salary: filter?.salary,
          remote: [filter?.remote, filter?.hybrid, filter?.on_site],
          currency: filter?.currency?.search,
        },
      })
      .then((response) => {
        if (response.data.results) {
          setData((prev) => {
            const prevIds = prev.map((el) => {
              return el.id;
            });
            const newIds = response.data.results.map((el) => {
              return el.id;
            });
            if (!prevIds.includes(newIds[0])) {
              prev.push(...response.data.results);
              setNext(response.data.next);
              setPrev(response.data.previous);
              setJobsNo(response.data.count);
            }
            return [...prev];
          });
        }
      })
      .catch((error) => {
        console.error(error); // handle errors here
      });
  };

  useEffect(() => {
    if (password) {
      let datas = JSON.stringify({
        username: username,
        password: password,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${BACKEND_URL}/api/token/`,
        headers: {
          "Content-Type": "application/json",
        },
        data: datas,
      };

      axios
        .request(config)
        .then((response) => {
          if (response?.data?.access) {
            localStorage.setItem("token", response.data.access);
            setUserToken(response.data.access, () => {});
            setUserId(jwtDecode(response.data.access)?.user_id);
            axios
              .get(
                `${BACKEND_URL}/api/designer/${
                  jwtDecode(localStorage.getItem("token"))?.user_id
                }`
              )
              .then((response) => {
                if (response.data) {
                  setDesignerData(response.data);
                  setUserRole("designer");
                  localStorage.setItem("user_role", "designer");
                }
              })
              .catch((error) => {
                setDesignerData(false);
                setUserRole("company");
                localStorage.setItem("user_role", "company");
                console.error(error); // handle errors here
              });
          }

          //   console.log(jwtDecode(response.data.access));
        })
        .catch((error) => {
          console.log(error?.response?.data?.error);
        });
    }
  }, []);
  return (
    <div className="content-hero">
      <TopPageIntro />
      <ListSearch
        data={data}
        filter={filter}
        setFilter={(val) => setFilter(val)}
        jobsNo={jobsNo}
        companies={false}
        designer={designerData}
        userId={userId}
        handlePagination={(res) => {
          handlePagination(res);
        }}
        next={next}
        prev={prev}
        pagination={true}
        showUserPopup={() => {
          showUserPopup();
        }}
      />
    </div>
  );
}

export default PageView;
