import "../../styles/items.scss";
import CustomIcon from "../../data/IconCompo";
import CompanyPic from "../atoms/CompanyPic";
import Button from "../atoms/Button";
import Input from "../atoms/Input";
import Status from "../atoms/Status";
import Tags from "../atoms/Tag";
import CheckButton from "../atoms/CheckBtn";
import RadioButton from "../atoms/RadioBtn";
import Nav from "../atoms/Nav";
import CompanyCard from "./CompanyCard";
import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

export default function ImageUpload({
  setImage,
  error,
  imagePreview,
  setImagePreview,
  horizontal,
  edit,
  className,
}) {
  const fileInputRef = useRef(null);
  const handleFileSelect = () => {
    const file = fileInputRef.current.files[0];
    setImage(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(file);
  };
  const lang = useSelector((state) => state.lang);

  return (
    <div
      className={`image_upload_cont ${
        horizontal && "image_cont_horizontal"
      } ${className}`}
    >
      <div className={`image_upload_frame ${error && "input_error"}`}>
        <input
          type="file"
          className="input-file-image"
          ref={fileInputRef}
          onChange={handleFileSelect}
        />
        {imagePreview ? (
          <img
            src={imagePreview}
            alt="Image Preview"
            style={{ width: "100%", height: "100%", objectFit: "cover" }}
          />
        ) : (
          <CustomIcon iconName="company" className="image_upload_icon" />
        )}
      </div>
      {edit && (
        <Button
          text={lang == "en" ? "Upload" : "アップロード"}
          className={"btn_c_line signup-upload-btn"}
          onClick={() => fileInputRef.current.click()}
        />
      )}
    </div>
  );
}
