import CustomIcon from "../data/IconCompo";
import "../styles/main.scss";

function Soon({}) {
  return (
    <div className="soon-cont">
      <CustomIcon iconName={"illust_20"} className="soon-icon" />
      <h1 className="soon-text">LAUNCHING <br/>SOON!</h1>
    </div>
  );
}

export default Soon;
