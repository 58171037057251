// RadioButton.js
import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomIcon from "../../data/IconCompo";

const RadioButton = ({ value, iconUnchecked, iconChecked, label, className }) => {
  const [selectedValue, setSelectedValue] = useState("");

  const handleRadioChange = () => {
    setSelectedValue(value);
  };

  return (
    <div className={`check_radio_box ${className}`} onClick={handleRadioChange}>
      <CustomIcon
        iconName={selectedValue === value ? iconChecked : iconUnchecked}
        className={selectedValue === value ? "checked" : "unchecked"}
      />
      {label}
    </div>
  );
};

RadioButton.propTypes = {
  value: PropTypes.any.isRequired,
  iconUnchecked: PropTypes.string.isRequired,
  iconChecked: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
};

RadioButton.defaultProps = {
  className: "",
};

export default RadioButton;
