import React, { useEffect, useState } from "react";
import ReactDOMServer from "react-dom/server";
import ReactQuill, { Quill, editor } from "react-quill";
import "../../styles/textarea.scss";
// import ImageResize  from 'quill-image-resize-module';
import "react-quill/dist/quill.snow.css";

import CustomToolbar from "./CustomToolBar";

// Quill.register('modules/ImageResize',ImageResize);
const Editor = ({ value, onChange, error, label }) => {
  const handleChange = (html) => {
    onChange(html);
  };
  const modules = {
    toolbar: {
      container: "#toolbar",
    },
  };
  const formats = [
    "size",
    "bold",
    "italic",
    "underline",

    "color",

    "header",
    "blockquote",

    "link",
    "indent",
    "list",
  ];
  useEffect(() => {
    if (error) {
      document
        .querySelector(".ql-container")
        .style.setProperty("border", "2px solid #ff0000", "important");
    } else {
      document.querySelector(".ql-container").style.border = "1px solid #ccc";
    }
  }, [error]);
  useEffect(() => {
    if (value == "<p><br></p>") {
      onChange();
    }
  }, [value]);
  return (
    <div className="textarea-cont">
      <p className="textarea-label">{label}</p>
      <CustomToolbar />
      <ReactQuill
        value={value}
        onChange={handleChange}
        modules={modules}
        formats={formats}
      />
    </div>
  );
};

export default Editor;
