import "../../styles/main.scss";
import "../../styles/main.scss";
// import CustomIcon from "../../data/IconCompo";

export default function Separator({ text, className }) {

  return (
    <div className="separator_box">
      </div>
  );
}
