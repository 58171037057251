import React, { useState } from "react";
import "../../styles/atoms.scss"; // Import CSS for styling

const PopupTab = ({ tab1Name, tab2Name, setLogin }) => {
  const [activeTab, setActiveTab] = useState(1); // State to track active tab

  // Function to handle tab click
  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
    if (tabNumber == 1) {
      setLogin(true);
    } else if (tabNumber == 2) {
      setLogin(false);
    }
  };

  return (
    <div className="popup-tab-container">
      <div
        className={`tab ${activeTab === 1 ? "active-tab" : ""}`}
        onClick={() => handleTabClick(1)}
      >
        {tab1Name}
      </div>
      <div
        className={`tab ${activeTab === 2 ? "active-tab" : ""}`}
        onClick={() => handleTabClick(2)}
      >
        {tab2Name}
      </div>
    </div>
  );
};

export default PopupTab;
