import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Layout from "./compo/templates/Layout";
import DesignSys from "./pages/DesignSys";
import Page from "./pages/Page";
import Items from "./pages/Items_View";
import Popups from "./pages/Popups_View";
import Templates from "./compo/templates/ListSearch";
import Home from "./pages/PageView";
import PlansPage from "./pages/PlansPage";
import SignupPage from "./pages/SignupPage";
import PostCreate from "./pages/PostCreate";
import JobDetail from "./compo/templates/JobDetail";
import Company from "./pages/Company";
import Companies from "./pages/Companies";
import CompanyUpdate from "./pages/CompanyUpdate";
import Signin from "./pages/Signin";
import DesignerSignup from "./pages/DesignerSignup";
import Pins from "./pages/Pins";
import MyJobs from "./pages/MyJobs";
import UserUpdate from "./pages/UserUpdate";
import { Provider } from "react-redux"; // Import Provider
import store from "./redux/store";
import ContactForm from "./compo/templates/ContactForm";
import EntryForm from "./pages/EntryForm";
import EntryPage from "./pages/EntryPage";
import Soon from "./pages/Soon";
import Loon from "./pages/SoonRedirect";

const App = () => {
  const [popupData, setPopupData] = useState(false);
  const [infoPopupData, setInfoPopupData] = useState(false);
  const [userPopup, setUserPopup] = useState(false);
  const [companyPopup, setCompanyPopup] = useState(false);
  const [userToken, setUserToken] = useState(localStorage.getItem("token"));
  const [userRole, setUserRole] = useState();
  const [companyData, setCompanyData] = useState(
    localStorage.getItem("companyData")
      ? JSON.parse(localStorage.getItem("companyData"))
      : ""
  );
  const [designerData, setDesignerData] = useState(
    localStorage.getItem("designerData") && localStorage.getItem("pins")
      ? {
          ...JSON.parse(localStorage.getItem("designerData")),
          pin: JSON.parse(localStorage.getItem("pins"))
            .slice(1)
            .map((el) => {
              return el.id;
            }),
        }
      : ""
  );

  function showPopup(title, text) {
    setPopupData({ title: title, text: text });
  }
  function closePopup() {
    setPopupData(false);
  }
  function showUserPopup() {
    setUserPopup(true);
  }
  function showCompanyPopup() {
    setCompanyPopup(true);
  }
  function showInfoPopup(type, text, time = 4000, icon) {
    if (type) {
      setInfoPopupData({
        text: text,
        type: type,
        time: time,
        iconName: icon,
      });
    } else {
      setInfoPopupData(false);
    }
  }
  return (
    <React.StrictMode>
      <Router>
        <Provider store={store}>
          <Layout
            designerData={designerData}
            popupData={popupData}
            userPopup={userPopup}
            companyPopup={companyPopup}
            infoPopupData={infoPopupData}
            userToken={userToken}
            userRole={userRole}
            companyData={companyData}
            setPopupData={(res) => {
              setPopupData(res);
            }}
            setInfoPopupData={(res) => {
              setInfoPopupData(res);
            }}
            setUserPopup={(res) => {
              setUserPopup(res);
            }}
            setCompanyPopup={(res) => {
              setCompanyPopup(res);
            }}
            showPopup={(title, text) => {
              showPopup(title, text);
            }}
            showInfoPopup={(title, text, iconName) => {
              showInfoPopup(title, text, iconName);
            }}
            showUserPopup={() => {
              showUserPopup();
            }}
            setCompanyData={(res) => {
              setCompanyData(res);
            }}
            showCompanyPopup={() => {
              showCompanyPopup();
            }}
            setUserRole={(res) => {
              setUserRole(res);
            }}
            setDesignerData={(res) => {
              setDesignerData(res);
            }}
            setUserToken={(res) => {
              setUserToken(res);
            }}
          >
            <Routes>
              <Route
                path="/entry-form"
                element={
                  <EntryForm
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />
              <Route
                path="/entry-page"
                element={
                  <EntryPage
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />
              <Route
                path="/contact-form"
                element={
                  <ContactForm
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />

              <Route path="/:id" element={<Loon />} />
              <Route path="/soon" element={<Soon />} />

              <Route path="/" element={<Soon />} />
              {/*
              <Route path="/design-system" element={<DesignSys />} />
              <Route path="/items" element={<Items />} />
              <Route path="/templates" element={<Templates />} />
              <Route
                path="/home"
                element={
                  <Home
                    setUserToken={(res) => {
                      setUserToken(res);
                    }}
                    setDesignerData={(res) => {
                      setDesignerData(res);
                    }}
                    designerData={designerData}
                    showUserPopup={() => {
                      showUserPopup();
                    }}
                  />
                }
              />
              <Route
                path="/home/:username/:password"
                element={
                  <Home
                    setUserToken={(res) => {
                      setUserToken(res);
                    }}
                    setUserRole={(res) => {
                      setUserRole(res);
                    }}
                    setDesignerData={(res) => {
                      setDesignerData(res);
                    }}
                    designerData={designerData}
                    showUserPopup={() => {
                      showUserPopup();
                    }}
                  />
                }
              />
              <Route path="/popups" element={<Popups />} />
              <Route
                path="/signup"
                element={
                  <SignupPage
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />

              <Route
                path="/job_detail/:postId"
                element={
                  <JobDetail
                    designerData={designerData}
                    userToken={userToken}
                  />
                }
              />
              <Route path="/choose_plan" element={<PlansPage />} />
              <Route
                path="/post/create"
                element={
                  <PostCreate
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />
              <Route
                path="/pinjob.me/:username"
                element={
                  <Company
                    designerData={designerData}
                    showUserPopup={() => {
                      showUserPopup();
                    }}
                  />
                }
              />
              <Route path="/companies" element={<Companies />} />
              <Route
                path="/company/update"
                element={
                  <CompanyUpdate
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />
              <Route
                path="/user/update"
                element={
                  <UserUpdate
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                  />
                }
              />
              <Route path="/signin" element={<Signin />} />
              <Route path="/designer/signup" element={<DesignerSignup />} />
              <Route
                path="/pins"
                element={
                  <Pins
                    setDesignerData={(res) => {
                      setDesignerData(res);
                    }}
                  />
                }
              />
              <Route
                path="/myjobs"
                element={
                  <MyJobs
                    showPopup={(title, text) => {
                      showPopup(title, text);
                    }}
                    showInfoPopup={(title, text, time, iconName) => {
                      showInfoPopup(title, text, time, iconName);
                    }}
                    closePopup={() => {
                      closePopup();
                    }}
                    setCompanyData={(res) => {
                      setCompanyData(res);
                    }}
                  />
                }
              />*/}
            </Routes>
          </Layout>
        </Provider>
      </Router>
    </React.StrictMode>
  );
};

ReactDOM.render(<App />, document.getElementById("root"));
