const colors = ["#00DEA3", "#15A6F8", "#FF6A56", "#989cca", "#CCCEDE"];
const formats = [
  [
    { 
      className: "ql-bold" 
    }, 
    {
      className: "ql-color",
      options: colors,
    },

    {
      className: "ql-header",
      value: "1",
    },
    {
      className: "ql-header",
      value: "2",
    },
    {
      className: "ql-list",
      value: "ordered",
    },
    {
      className: "ql-list",
      value: "bullet",
    },
    {
      className: "ql-link",
    },
  ],
];

export default formats;
