import { combineReducers } from "redux";

const languageReducer = (state = "jp", action) => {
  switch (action.type) {
    case "CHANGE_LANGUAGE":
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  lang: languageReducer, // Add the language reducer here
});
