export function signupValidation(formData, desc, image, lang) {
  const errors = {};
  const jpLabels = {
    jobTitle: "職種",
    jobType: "雇用形態",
    country_user: "都道府県",
    city_user: "市区町村",
    endpoint: "連絡先",
    remote: "勤務地",
    minimum_salary: "最低額",
    maximum_salary: "最高額",
    job_description: "仕事内容",
    username: "ユーザーネーム",
    email: "メールアドレス",
    url: "会社のURL",
    company: "会社名",
    image: "会社のロゴ",
    country: "雇用形態",
    name: "お名前",
    city: "市区町村",
  };
  const enLabels = {
    jobTitle: "en",
    jobType: "en",
    country_user: "en",
    city_user: "en",
    endpoint: "en",
    remote: "en",
    minimum_salary: "en",
    maximum_salary: "en",
    job_description: "en",
    name: "en",
    username: "en",
    email: "en",
    url: "en",
    company: "en",
    image: "en",
    country: "en",
    name: "en",
    city: "en",
  };
  // Check if all fields are present
  let requiredFields = {};
  if (lang == "en") {
    requiredFields =
      formData?.remote?.value === "remote"
        ? [
            "jobTitle",
            "jobType",
            "country_user",
            "city_user",
            "endpoint",
            "remote",
            "minimum_salary",
            "maximum_salary",
            "job_description",
            "name",
            "username",
            "email",
            "url",
            "company",
            "image",
            "name",
          ]
        : [
            "jobTitle",
            "jobType",
            "country_user",
            "city_user",
            "endpoint",
            "remote",
            "minimum_salary",
            "maximum_salary",
            "job_description",
            "name",
            "username",
            "email",
            "url",
            "company",
            "image",
            "country",
            "name",
            "city",
          ];
  } else {
    requiredFields =
      formData?.remote?.value === "remote"
        ? [
            "jobTitle",
            "jobType",
            "prefecture_user",
            "city_user",
            "endpoint",
            "remote",
            "minimum_salary",
            "maximum_salary",
            "job_description",
            "name",
            "username",
            "email",
            "url",
            "company",
            "image",
            "name",
          ]
        : [
            "jobTitle",
            "jobType",
            "prefecture_user",
            "city_user",
            "endpoint",
            "remote",
            "minimum_salary",
            "maximum_salary",
            "job_description",
            "name",
            "username",
            "email",
            "url",
            "company",
            "image",
            "prefecture",
            "name",
            "city",
          ];
  }

  requiredFields.forEach((field) => {
    if (field === "image" && !image) {
      errors[field] =
        lang === "en"
          ? `${enLabels[field]} field is required`
          : `${jpLabels[field]} フィールドは必須です`;
    } else if (field === "job_description" && !desc) {
      errors.job_description =
        lang === "en"
          ? `job_description field is required`
          : `仕事内容を入力してください。`;
    } else if (
      !formData[field] &&
      field !== "image" &&
      field !== "job_description"
    ) {
      errors[field] =
        lang === "en"
          ? `${enLabels[field]} field is required`
          : `${jpLabels[field]} フィールドは必須です`;
    }
  });
  // Additional validation for specific fields (if needed)
  if (image.size > 1000000) {
    errors.image =
      lang === "en"
        ? "Image size is to big please upload another image"
        : `画像のサイズが大きすぎますので、別の画像をアップロードしてください`;
  }
  if (formData.maximum_salary && isNaN(formData.maximum_salary)) {
    errors.maximum_salary =
      lang === "en"
        ? "Maximum salary must be a number"
        : `最高額（月給）を入力してください。`;
  }
  if (lang == "en") {
    if (!formData?.country?.search && formData?.remote?.value !== "remote") {
      errors.country =
        lang === "en"
          ? "Country field is required"
          : `都道府県を選択してください。`;
    }
    console.log(formData?.country_user?.search);
    if (
      !formData?.country_user?.search &&
      formData?.remote?.value !== "remote"
    ) {
      errors.country_user =
        lang === "en"
          ? "Country field is required"
          : `都道府県を選択してください。`;
    }
  } else {
    if (!formData?.prefecture?.search && formData?.remote?.value !== "remote") {
      errors.prefecture =
        lang === "en"
          ? "prefecture field is required"
          : `都道府県を選択してください。`;
    }
    console.log(formData?.prefecture_user?.search);
    if (
      !formData?.prefecture_user?.search &&
      formData?.remote?.value !== "remote"
    ) {
      errors.prefecture_user =
        lang === "en"
          ? "prefecture field is required"
          : `都道府県を選択してください。`;
    }
  }

  if (formData.minimum_salary && isNaN(formData.minimum_salary)) {
    errors.minimum_salary =
      lang === "en"
        ? "Minimum salary must be a number"
        : `最低額（月給）を入力してください。`;
  }
  if (parseInt(formData.maximum_salary) < parseInt(formData.minimum_salary)) {
    errors.maximum_salary =
      lang === "en"
        ? "Maximum salary must be a number"
        : `最高額（月給）を入力してください。`;
    errors.minimum_salary =
      lang === "en"
        ? "Minimum salary must be a number"
        : `最低額（月給）を入力してください。`;
  }
  if (!validateEndpoint(formData.endpoint)) {
    errors.endpoint =
      lang === "en"
        ? "Endpoint must be an endpoint or url"
        : `正しいURLを入力してください。`;
  }
  if (!validateUrl(formData.url)) {
    errors.url =
      lang === "en" ? "Enter valid url" : `正しいURLを入力してください。`;
  }
  if (formData["email"] && !validateEmail(formData["email"])) {
    errors["email"] =
      lang === "en"
        ? `Invalid email format`
        : `正しいメールアドレスを入力してください。`;
  }
  console.log(errors);
  return errors;
}

export function contactFormValidation(formData, lang) {
  const errors = {};
  const jpLabels = {
    name: "jp",
    email: "jp",
    phone: "jp",
    company: "jp",
  };
  const enLabels = {
    name: "en",
    email: "en",
    phone: "en",
    company: "en",
  };
  // Check if all fields are present
  let requiredFields = ["name", "email", "company", "subject_title", "desc"];

  requiredFields.forEach((field) => {
    if (!formData[field] && field !== "image" && field !== "job_description") {
      errors[field] =
        lang === "en"
          ? `${enLabels[field]} field is required`
          : `${jpLabels[field]} フィールドは必須です`;
    }
  });

  if (formData["email"] && !validateEmail(formData["email"])) {
    errors["email"] =
      lang === "en"
        ? `Invalid email format`
        : `正しいメールアドレスを入力してください。`;
  }
  return errors;
}

export function UserLogin(formData) {
  const errors = {};

  // Check if all fields are present
  const requiredFields = ["login_email"];

  requiredFields.forEach((field) => {
    if (!formData[field]) {
      errors[field] = `${field} field is required`;
    }
  });
  // Additional validation for specific fields (if needed)

  if (formData["login_email"] && !validateEmail(formData["login_email"])) {
    errors["login_email"] = `Invalid email format`;
  }

  return errors;
}

export function UserSignup(formData) {
  const errors = {};

  // Check if all fields are present
  const requiredFields = ["name", "username", "email"];

  requiredFields.forEach((field) => {
    if (!formData[field]) {
      errors[field] = `${field} field is required`;
    }
  });
  // Additional validation for specific fields (if needed)

  if (formData["email"] && !validateEmail(formData["email"])) {
    errors["email"] = `Invalid email format`;
  }

  return errors;
}

function validateEmail(email) {
  // Regular expression for validating email format
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
}

function validateEndpoint(value) {
  // Regular expression to validate URL
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

  // Regular expression to validate email
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Check if the value is a valid URL or email
  return urlRegex.test(value) || emailRegex.test(value);
}
function validateUrl(value) {
  // Regular expression to validate URL
  const urlRegex =
    /^(?:(?:https?|ftp):\/\/)?(?:www\.)?[\w-]+(\.[\w-]+)+([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?$/;

  // Check if the value is a valid URL or email
  return urlRegex.test(value);
}
