import flag_AD from "../../assets/flags/AD.svg";
import flag_AE from "../../assets/flags/AE.svg";
import flag_AF from "../../assets/flags/AF.svg";
import flag_AG from "../../assets/flags/AG.svg";
import flag_AI from "../../assets/flags/AI.svg";
import flag_AL from "../../assets/flags/AL.svg";
import flag_AM from "../../assets/flags/AM.svg";
import flag_AO from "../../assets/flags/AO.svg";
import flag_AR from "../../assets/flags/AR.svg";
import flag_AS from "../../assets/flags/AS.svg";
import flag_AT from "../../assets/flags/AT.svg";
import flag_AU from "../../assets/flags/AU.svg";
import flag_AW from "../../assets/flags/AW.svg";
import flag_AZ from "../../assets/flags/AZ.svg";
import flag_BA from "../../assets/flags/BA.svg";
import flag_BB from "../../assets/flags/BB.svg";
import flag_BD from "../../assets/flags/BD.svg";
import flag_BE from "../../assets/flags/BE.svg";
import flag_BF from "../../assets/flags/BF.svg";
import flag_BG from "../../assets/flags/BG.svg";
import flag_BH from "../../assets/flags/BH.svg";
import flag_BI from "../../assets/flags/BI.svg";
import flag_BJ from "../../assets/flags/BJ.svg";
import flag_BM from "../../assets/flags/BM.svg";
import flag_BN from "../../assets/flags/BN.svg";
import flag_BO from "../../assets/flags/BO.svg";
import flag_BR from "../../assets/flags/BR.svg";
import flag_BS from "../../assets/flags/BS.svg";
import flag_BT from "../../assets/flags/BT.svg";
import flag_BW from "../../assets/flags/BW.svg";
import flag_BY from "../../assets/flags/BY.svg";
import flag_BZ from "../../assets/flags/BZ.svg";
import flag_CA from "../../assets/flags/CA.svg";
import flag_CC from "../../assets/flags/CC.svg";
import flag_CD from "../../assets/flags/CD.svg";
import flag_CF from "../../assets/flags/CF.svg";
import flag_CG from "../../assets/flags/CG.svg";
import flag_CH from "../../assets/flags/CH.svg";
import flag_CI from "../../assets/flags/CI.svg";
import flag_CK from "../../assets/flags/CK.svg";
import flag_CL from "../../assets/flags/CL.svg";
import flag_CM from "../../assets/flags/CM.svg";
import flag_CN from "../../assets/flags/CN.svg";
import flag_CO from "../../assets/flags/CO.svg";
import flag_CR from "../../assets/flags/CR.svg";
import flag_CU from "../../assets/flags/CU.svg";
import flag_CV from "../../assets/flags/CV.svg";
import flag_CW from "../../assets/flags/CW.svg";
import flag_CX from "../../assets/flags/CX.svg";
import flag_CY from "../../assets/flags/CY.svg";
import flag_CZ from "../../assets/flags/CZ.svg";
import flag_DE from "../../assets/flags/DE.svg";
import flag_DJ from "../../assets/flags/DJ.svg";
import flag_DK from "../../assets/flags/DK.svg";
import flag_DM from "../../assets/flags/DM.svg";
import flag_DO from "../../assets/flags/DO.svg";
import flag_DZ from "../../assets/flags/DZ.svg";
import flag_EC from "../../assets/flags/EC.svg";
import flag_EE from "../../assets/flags/EE.svg";
import flag_EG from "../../assets/flags/EG.svg";
import flag_ER from "../../assets/flags/ER.svg";
import flag_ES from "../../assets/flags/ES.svg";
import flag_ET from "../../assets/flags/ET.svg";
import flag_FI from "../../assets/flags/FI.svg";
import flag_FJ from "../../assets/flags/FJ.svg";
import flag_FK from "../../assets/flags/FK.svg";
import flag_FM from "../../assets/flags/FM.svg";
import flag_FR from "../../assets/flags/FR.svg";
import flag_GA from "../../assets/flags/GA.svg";
import flag_GB from "../../assets/flags/GB.svg";
import flag_GD from "../../assets/flags/GD.svg";
import flag_GE from "../../assets/flags/GE.svg";
import flag_GG from "../../assets/flags/GG.svg";
import flag_GH from "../../assets/flags/GH.svg";
import flag_GI from "../../assets/flags/GI.svg";
import flag_GL from "../../assets/flags/GL.svg";
import flag_GM from "../../assets/flags/GM.svg";
import flag_GN from "../../assets/flags/GN.svg";
import flag_GQ from "../../assets/flags/GQ.svg";
import flag_GR from "../../assets/flags/GR.svg";
import flag_GT from "../../assets/flags/GT.svg";
import flag_GU from "../../assets/flags/GU.svg";
import flag_GW from "../../assets/flags/GW.svg";
import flag_HK from "../../assets/flags/HK.svg";
import flag_HN from "../../assets/flags/HN.svg";
import flag_HR from "../../assets/flags/HR.svg";
import flag_HT from "../../assets/flags/HT.svg";
import flag_HU from "../../assets/flags/HU.svg";
import flag_ID from "../../assets/flags/ID.svg";
import flag_IE from "../../assets/flags/IE.svg";
import flag_IL from "../../assets/flags/IL.svg";
import flag_IN from "../../assets/flags/IN.svg";
import flag_IO from "../../assets/flags/IO.svg";
import flag_IQ from "../../assets/flags/IQ.svg";
import flag_IR from "../../assets/flags/IR.svg";
import flag_IS from "../../assets/flags/IS.svg";
import flag_IT from "../../assets/flags/IT.svg";
import flag_JE from "../../assets/flags/JE.svg";
import flag_JM from "../../assets/flags/JM.svg";
import flag_JO from "../../assets/flags/JO.svg";
import flag_JP from "../../assets/flags/JP.svg";
import flag_KE from "../../assets/flags/KE.svg";
import flag_KG from "../../assets/flags/KG.svg";
import flag_KH from "../../assets/flags/KH.svg";
import flag_KI from "../../assets/flags/KI.svg";
import flag_KM from "../../assets/flags/KM.svg";
import flag_KN from "../../assets/flags/KN.svg";
import flag_KP from "../../assets/flags/KP.svg";
import flag_KR from "../../assets/flags/KR.svg";
import flag_KW from "../../assets/flags/KW.svg";
import flag_KY from "../../assets/flags/KY.svg";
import flag_KZ from "../../assets/flags/KZ.svg";
import flag_LA from "../../assets/flags/LA.svg";
import flag_LB from "../../assets/flags/LB.svg";
import flag_LC from "../../assets/flags/LC.svg";
import flag_LI from "../../assets/flags/LI.svg";
import flag_LK from "../../assets/flags/LK.svg";
import flag_LR from "../../assets/flags/LR.svg";
import flag_LS from "../../assets/flags/LS.svg";
import flag_LT from "../../assets/flags/LT.svg";
import flag_LU from "../../assets/flags/LU.svg";
import flag_LV from "../../assets/flags/LV.svg";
import flag_LY from "../../assets/flags/LY.svg";
import flag_MA from "../../assets/flags/MA.svg";
import flag_MC from "../../assets/flags/MC.svg";
import flag_MD from "../../assets/flags/MD.svg";
import flag_ME from "../../assets/flags/ME.svg";
import flag_MG from "../../assets/flags/MG.svg";
import flag_MH from "../../assets/flags/MH.svg";
import flag_MK from "../../assets/flags/MK.svg";
import flag_ML from "../../assets/flags/ML.svg";
import flag_MM from "../../assets/flags/MM.svg";
import flag_MN from "../../assets/flags/MN.svg";
import flag_MO from "../../assets/flags/MO.svg";
import flag_MP from "../../assets/flags/MP.svg";
import flag_MQ from "../../assets/flags/MQ.svg";
import flag_MR from "../../assets/flags/MR.svg";
import flag_MS from "../../assets/flags/MS.svg";
import flag_MT from "../../assets/flags/MT.svg";
import flag_MU from "../../assets/flags/MU.svg";
import flag_MV from "../../assets/flags/MV.svg";
import flag_MW from "../../assets/flags/MW.svg";
import flag_MX from "../../assets/flags/MX.svg";
import flag_MY from "../../assets/flags/MY.svg";
import flag_MZ from "../../assets/flags/MZ.svg";
import flag_NA from "../../assets/flags/NA.svg";
import flag_NC from "../../assets/flags/NC.svg";
import flag_NE from "../../assets/flags/NE.svg";
import flag_NF from "../../assets/flags/NF.svg";
import flag_NG from "../../assets/flags/NG.svg";
import flag_NI from "../../assets/flags/NI.svg";
import flag_NL from "../../assets/flags/NL.svg";
import flag_NO from "../../assets/flags/NO.svg";
import flag_NP from "../../assets/flags/NP.svg";
import flag_NR from "../../assets/flags/NR.svg";
import flag_NU from "../../assets/flags/NU.svg";
import flag_NZ from "../../assets/flags/NZ.svg";
import flag_OM from "../../assets/flags/OM.svg";
import flag_PA from "../../assets/flags/PA.svg";
import flag_PE from "../../assets/flags/PE.svg";
import flag_PF from "../../assets/flags/PF.svg";
import flag_PG from "../../assets/flags/PG.svg";
import flag_PH from "../../assets/flags/PH.svg";
import flag_PK from "../../assets/flags/PK.svg";
import flag_PL from "../../assets/flags/PL.svg";
import flag_PN from "../../assets/flags/PN.svg";
import flag_PR from "../../assets/flags/PR.svg";
import flag_PS from "../../assets/flags/PS.svg";
import flag_PT from "../../assets/flags/PT.svg";
import flag_PW from "../../assets/flags/PW.svg";
import flag_PY from "../../assets/flags/PY.svg";
import flag_QA from "../../assets/flags/QA.svg";
import flag_RO from "../../assets/flags/RO.svg";
import flag_RS from "../../assets/flags/RS.svg";
import flag_RU from "../../assets/flags/RU.svg";
import flag_RW from "../../assets/flags/RW.svg";
import flag_SA from "../../assets/flags/SA.svg";
import flag_SB from "../../assets/flags/SB.svg";
import flag_SC from "../../assets/flags/SC.svg";
import flag_SD from "../../assets/flags/SD.svg";
import flag_SE from "../../assets/flags/SE.svg";
import flag_SG from "../../assets/flags/SG.svg";
import flag_SI from "../../assets/flags/SI.svg";
import flag_SK from "../../assets/flags/SK.svg";
import flag_SL from "../../assets/flags/SL.svg";
import flag_SM from "../../assets/flags/SM.svg";
import flag_SN from "../../assets/flags/SN.svg";
import flag_SO from "../../assets/flags/SO.svg";
import flag_SR from "../../assets/flags/SR.svg";
import flag_SS from "../../assets/flags/SS.svg";
import flag_ST from "../../assets/flags/ST.svg";
import flag_SV from "../../assets/flags/SV.svg";
import flag_SY from "../../assets/flags/SY.svg";
import flag_SZ from "../../assets/flags/SZ.svg";
import flag_TC from "../../assets/flags/TC.svg";
import flag_TD from "../../assets/flags/TD.svg";
import flag_TG from "../../assets/flags/TG.svg";
import flag_TH from "../../assets/flags/TH.svg";
import flag_TJ from "../../assets/flags/TJ.svg";
import flag_TK from "../../assets/flags/TK.svg";
import flag_TL from "../../assets/flags/TL.svg";
import flag_TM from "../../assets/flags/TM.svg";
import flag_TN from "../../assets/flags/TN.svg";
import flag_TO from "../../assets/flags/TO.svg";
import flag_TR from "../../assets/flags/TR.svg";
import flag_TT from "../../assets/flags/TT.svg";
import flag_TV from "../../assets/flags/TV.svg";
import flag_TW from "../../assets/flags/TW.svg";
import flag_TZ from "../../assets/flags/TZ.svg";
import flag_UA from "../../assets/flags/UA.svg";
import flag_UG from "../../assets/flags/UG.svg";
import flag_US from "../../assets/flags/US.svg";
import flag_UY from "../../assets/flags/UY.svg";
import flag_UZ from "../../assets/flags/UZ.svg";
import flag_VA from "../../assets/flags/VA.svg";
import flag_VC from "../../assets/flags/VC.svg";
import flag_VE from "../../assets/flags/VE.svg";
import flag_VG from "../../assets/flags/VG.svg";
import flag_VI from "../../assets/flags/VI.svg";
import flag_VN from "../../assets/flags/VN.svg";
import flag_VU from "../../assets/flags/VU.svg";
import flag_XK from "../../assets/flags/XK.svg";
import flag_WS from "../../assets/flags/WS.svg";
import flag_YE from "../../assets/flags/YE.svg";
import flag_ZA from "../../assets/flags/ZA.svg";
import flag_ZM from "../../assets/flags/ZM.svg";
import flag_ZW from "../../assets/flags/ZW.svg";

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  flag_AD,
  flag_AE,
  flag_AF,
  flag_AG,
  flag_AI,
  flag_AL,
  flag_AM,
  flag_AO,
  flag_AR,
  flag_AS,
  flag_AT,
  flag_AU,
  flag_AW,
  flag_AZ,
  flag_BA,
  flag_BB,
  flag_BD,
  flag_BE,
  flag_BF,
  flag_BG,
  flag_BH,
  flag_BI,
  flag_BJ,
  flag_BM,
  flag_BN,
  flag_BO,
  flag_BR,
  flag_BS,
  flag_BT,
  flag_BW,
  flag_BY,
  flag_BZ,
  flag_CA,
  flag_CC,
  flag_CD,
  flag_CF,
  flag_CG,
  flag_CH,
  flag_CI,
  flag_CK,
  flag_CL,
  flag_CM,
  flag_CN,
  flag_CO,
  flag_CR,
  flag_CU,
  flag_CV,
  flag_CW,
  flag_CX,
  flag_CY,
  flag_CZ,
  flag_DE,
  flag_DJ,
  flag_DK,
  flag_DM,
  flag_DO,
  flag_DZ,
  flag_EC,
  flag_EE,
  flag_EG,
  flag_ER,
  flag_ES,
  flag_ET,
  flag_FI,
  flag_FJ,
  flag_FK,
  flag_FM,
  flag_FR,
  flag_GA,
  flag_GB,
  flag_GD,
  flag_GE,
  flag_GG,
  flag_GH,
  flag_GI,
  flag_GL,
  flag_GM,
  flag_GN,
  flag_GQ,
  flag_GR,
  flag_GT,
  flag_GU,
  flag_GW,
  flag_HK,
  flag_HN,
  flag_HR,
  flag_HT,
  flag_HU,
  flag_ID,
  flag_IE,
  flag_IL,
  flag_IN,
  flag_IO,
  flag_IQ,
  flag_IR,
  flag_IS,
  flag_IT,
  flag_JE,
  flag_JM,
  flag_JO,
  flag_JP,
  flag_KE,
  flag_KG,
  flag_KH,
  flag_KI,
  flag_KM,
  flag_KN,
  flag_KP,
  flag_KR,
  flag_KW,
  flag_KY,
  flag_KZ,
  flag_LA,
  flag_LB,
  flag_LC,
  flag_LI,
  flag_LK,
  flag_LR,
  flag_LS,
  flag_LT,
  flag_LU,
  flag_LV,
  flag_LY,
  flag_MA,
  flag_MC,
  flag_MD,
  flag_ME,
  flag_MG,
  flag_MH,
  flag_MK,
  flag_ML,
  flag_MM,
  flag_MN,
  flag_MO,
  flag_MP,
  flag_MQ,
  flag_MR,
  flag_MS,
  flag_MT,
  flag_MU,
  flag_MV,
  flag_MW,
  flag_MX,
  flag_MY,
  flag_MZ,
  flag_NA,
  flag_NC,
  flag_NE,
  flag_NF,
  flag_NG,
  flag_NI,
  flag_NL,
  flag_NO,
  flag_NP,
  flag_NR,
  flag_NU,
  flag_NZ,
  flag_OM,
  flag_PA,
  flag_PE,
  flag_PF,
  flag_PG,
  flag_PH,
  flag_PK,
  flag_PL,
  flag_PN,
  flag_PR,
  flag_PS,
  flag_PT,
  flag_PW,
  flag_PY,
  flag_QA,
  flag_RO,
  flag_RS,
  flag_RU,
  flag_RW,
  flag_SA,
  flag_SB,
  flag_SC,
  flag_SD,
  flag_SE,
  flag_SG,
  flag_SI,
  flag_SK,
  flag_SL,
  flag_SM,
  flag_SN,
  flag_SO,
  flag_SR,
  flag_SS,
  flag_ST,
  flag_SV,
  flag_SY,
  flag_SZ,
  flag_TC,
  flag_TD,
  flag_TG,
  flag_TH,
  flag_TJ,
  flag_TK,
  flag_TL,
  flag_TM,
  flag_TN,
  flag_TO,
  flag_TR,
  flag_TT,
  flag_TV,
  flag_TW,
  flag_TZ,
  flag_UA,
  flag_UG,
  flag_US,
  flag_UY,
  flag_UZ,
  flag_VA,
  flag_VC,
  flag_VE,
  flag_VG,
  flag_VI,
  flag_VN,
  flag_VU,
  flag_XK,
  flag_WS,
  flag_YE,
  flag_ZA,
  flag_ZM,
  flag_ZW,
};
