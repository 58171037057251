import axios from "axios";
import { BACKEND_URL } from "../config";

export const getToken = async (username, password) => {
  let data = JSON.stringify({
    username: username,
    password: password,
  });

  let config = {
    method: "post",
    maxBodyLength: Infinity,
    url: `${BACKEND_URL}/token/`,
    headers: {
      "Content-Type": "application/json",
    },
    data: data,
  };

  return await axios.request(config);
};
export const CreateDesigner = async (data) => {
  const requestData = new FormData();
  requestData.append("email", data.email);
  requestData.append("name", data.name);
  requestData.append("username", data.username.toLowerCase());

  return await axios.post(`${BACKEND_URL}/api/designer/create`, requestData, {
    headers: {
      "Content-Type": "multipart/form-data", // Ensure correct content type
    },
  });
};
export const Signin = async (email, role) => {
  return await axios.post(
    `${BACKEND_URL}/api/signin`,
    { email: email, role: role },
    {
      headers: {
        "Content-Type": "application/json", // Ensure correct content type
      },
    }
  );
};
