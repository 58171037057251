import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import CustomIcon from "../../data/IconCompo";

const PageTitle = ({ title, text, titleNumber, className, sectionTitle }) => {
  return (
    <div className={`page_title_box `}>
      {title && (
        <div className={`big_title ${className}`}>
                    {(titleNumber || titleNumber == 0) && <span>{titleNumber}</span>}

          {title}
        </div>
      )}
      {sectionTitle && <h4>{sectionTitle}</h4>}
      {text && <h5>{text}</h5>}
    </div>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string, // title is now optional
  text: PropTypes.string,
  titleNumber: PropTypes.number,
  className: PropTypes.string,
  sectionTitle: PropTypes.string,
};

export default PageTitle;
