import React, { useState, cloneElement, useEffect } from "react";
import Footer from "../items/Footer";
import Header from "../items/Header";
import PopupTemp from "./PopupTemp";
import InfoPopup from "../atoms/InfoPopup";
import HeaderLogged from "../items/HeaderLogged";
import axios from "axios";
import PopupCardCompany from "../items/PopupCardCompany";
import PopupCardUser from "../items/PopupCardUser";
import HeaderLoggedDesigner from "../items/HeaderLoggedDesigner";
import { jwtDecode } from "jwt-decode";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import HeaderSimple from "../items/HeaderSimple";
import FooterSimple from "../items/FooterSimple";

const Layout = ({
  children,
  popupData,
  setPopupData,
  infoPopupData,
  setInfoPopupData,
  showInfoPopup,
  showPopup,
  userToken,
  setUserPopup,
  userPopup,
  showUserPopup,
  setCompanyPopup,
  companyPopup,
  showCompanyPopup,
  userRole,
  designerData,
  setUserRole,
  setDesignerData,
  setUserToken,
  companyData,
  setCompanyData,
}) => {
  const location = useLocation();
  const path = location.pathname.substring(1);

  const [imgSrc, setImgSrc] = useState(
    localStorage.getItem("user_role") == "company" &&
      JSON.parse(localStorage.getItem("companyData"))
      ? JSON.parse(localStorage.getItem("companyData")).image
      : localStorage.getItem("user_role") == "designer" &&
        JSON.parse(localStorage.getItem("designerData"))
      ? JSON.parse(localStorage.getItem("designerData")).image
      : null
  );
  const [currentPath, setCurrentPath] = useState();
  const paths = [
    { path: "entry-form", header: <HeaderSimple />, footer: <FooterSimple /> },
    { path: "entry-page", header: <HeaderSimple />, footer: <FooterSimple /> },
    {
      path: "contact-form",
      header: <HeaderSimple />,
      footer: <FooterSimple />,
    },
    { path: "soon", header: <></>, footer: <></> },
    { path: "design-system", header: <Header />, footer: <Footer /> },
    { path: "items", header: <Header />, footer: <Footer /> },
    { path: "templates", header: <Header />, footer: <Footer /> },
    { path: "home", header: <Header />, footer: <Footer /> },
    { path: "popups", header: <Header />, footer: <Footer /> },
    { path: "signup", header: <Header />, footer: <Footer /> },
    { path: "job_detail", header: <Header />, footer: <Footer /> },
    { path: "choose_plan", header: <Header />, footer: <Footer /> },
    { path: "post", header: <Header />, footer: <Footer /> },
    { path: "/pinjob.me/:username", header: <Header />, footer: <Footer /> },
    { path: "companies", header: <Header />, footer: <Footer /> },
    { path: "company", header: <Header />, footer: <Footer /> },
    { path: "user", header: <Header />, footer: <Footer /> },
    { path: "signin", header: <Header />, footer: <Footer /> },
    { path: "designer", header: <Header />, footer: <Footer /> },
    { path: "pins", header: <Header />, footer: <Footer /> },
    { path: "myjobs", header: <Header />, footer: <Footer /> },
  ];
  useEffect(() => {
    let infoPopup = document.querySelector(".info-popup");
    let timerId; // Declare timerId variable outside of the setTimeout function
    if (infoPopupData && infoPopup) {
      // infoPopup.style.left = `calc( 50% - ${infoPopup.clientWidth / 2}px)`;
      setTimeout(() => {
        infoPopup.style.top = "32px";
      }, 100);

      // Cancel the existing timer if it exists
      clearTimeout(timerId);

      // Start a new timer
      timerId = setTimeout(() => {
        infoPopup.style.top = "-100px";
        setTimeout(() => {
          setInfoPopupData(false);
        }, 500);
      }, infoPopupData.time);
    }

    // Cleanup function to cancel the timer when the component unmounts or when infoPopupData changes
    return () => clearTimeout(timerId);
  }, [infoPopupData]);
  useEffect(() => {
    if (userToken) {
      if (localStorage.getItem("user_role") === "company") {
        setUserRole("company");

        axios
          .get("http://178.16.142.189:8002/api/companies", {
            params: {
              me: true,
            },
            headers: {
              Authorization: `Bearer ${userToken}`,
            },
          })
          .then((response) => {
            if (response.data) {
              localStorage.setItem(
                "companyData",
                JSON.stringify({
                  ...response.data.results[0],
                  image: response.data.results[0].image,
                })
              );
              setCompanyData(response.data.results[0]);
              setImgSrc(response.data.results[0].image);
            }
          })
          .catch((error) => {
            console.error(error); // handle errors here
          });
      } else if (localStorage.getItem("user_role") === "designer") {
        setUserRole("designer");
        axios
          .get(
            `http://178.16.142.189:8002/api/designer/${
              jwtDecode(localStorage.getItem("token"))?.user_id
            }`
          )
          .then((response) => {
            if (response.data) {
              localStorage.setItem(
                "designerData",
                JSON.stringify({
                  ...response.data,
                  image: response.data.image,
                })
              );
              setDesignerData(response.data);
              setImgSrc(response.data.image);

              localStorage.setItem("user_role", "designer");
            }
          })
          .catch((error) => {
            setDesignerData(false);
            localStorage.setItem("user_role", "company");
            console.error(error); // handle errors here
          });
      }
    }
  }, [userToken, userRole]);
  useEffect(() => {
    if (
      paths.includes(path) ||
      paths.filter((el) => {
        return path.includes(el.path);
      })
    ) {
      setCurrentPath(
        paths.find((el) => {
          return path.split("/")[0] === el.path;
        })
      );
    } else {
      setCurrentPath(false);
    }
  }, []);

  return (
    <>
      <div className="app">
        {currentPath ? (
          // <>here to put header</>
          currentPath.header
        ) : localStorage.getItem("user_role") ? (
          localStorage.getItem("user_role") == "company" ? (
            <HeaderLogged companyData={companyData} imgSrc={imgSrc} />
          ) : (
            <HeaderLoggedDesigner designerData={designerData} imgSrc={imgSrc} />
          )
        ) : userToken ? (
          userRole == "company" ? (
            <HeaderLogged companyData={companyData} imgSrc={imgSrc} />
          ) : (
            <HeaderLoggedDesigner designerData={designerData} imgSrc={imgSrc} />
          )
        ) : (
          <HeaderSimple></HeaderSimple>
        )}
        {children}

        {currentPath ? (
          // <>here to put header</>
          currentPath.footer
        ) : (
          <FooterSimple />
        )}
      </div>

      {popupData && (
        <div className="popup-cont">
          <PopupTemp
            text={popupData?.text}
            title={popupData?.title}
            showImage={true}
            showText={true}
            showTitle={true}
            onClose={() => {
              setPopupData(false);
            }}
          />
        </div>
      )}

      {infoPopupData && (
        <InfoPopup
          text={infoPopupData.text}
          type={infoPopupData.type}
          iconName={infoPopupData.iconName}
          // Make icon2 optional
          onClose={() => {
            document.querySelector(".info-popup").style.top = "-100px";
            setTimeout(() => {
              setInfoPopupData(false);
            }, 500);
          }}
        />
      )}
      {userPopup && (
        <div className="popup-cont">
          <PopupCardUser
            showPopup={(title, text) => {
              showPopup(title, text);
            }}
            showInfoPopup={(title, text, iconName) => {
              showInfoPopup(title, text, iconName);
            }}
            onClose={() => {
              setUserPopup(false);
            }}
          />
        </div>
      )}

      {companyPopup && (
        <div className="popup-cont">
          <PopupCardCompany
            showPopup={(title, text) => {
              showPopup(title, text);
            }}
            showInfoPopup={(title, text, iconName) => {
              showInfoPopup(title, text, iconName);
            }}
            onClose={() => {
              setCompanyPopup(false);
            }}
          />
        </div>
      )}
    </>
  );
};

export default Layout;
